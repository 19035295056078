<template>
	<div>
		<div class="field is-horizontal">
			<div class="field-body">
				<div class="field">
					<b-radio
						v-model="delivery.choice"
						name="deliveryChoice"
						native-value="postal"
						><span class="pl-2 pr-1">Post Office</span></b-radio
					>
				</div>
				<div class="field">
					<b-radio
						v-model="delivery.choice"
						name="deliveryChoice"
						native-value="address"
						><span class="pl-2 pr-1">Address</span></b-radio
					>
				</div>
			</div>
		</div>
		<section>
			<div v-if="delivery.choice == 'postal'">
				<div class="field has-addons">
					<p class="control">
						<a class="button is-static">Parish</a>
					</p>
					<div class="control is-expanded">
						<div class="select is-fullwidth">
							<select
								class="is-fullwidth"
								v-model="delivery.address.parish"
								@change="delivery.address.post_office = ''">
								<optgroup label="Parishes">
									<option
										value=""
										disabled
										selected
										v-html="
											'- Select the Parish of Post Office -'
										"></option>
									<option
										v-for="(parish, index) in getPostalParishes"
										:key="parish"
										:value="parish"
										v-html="parish"></option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
				<div class="field has-addons">
					<p class="control">
						<a class="button is-static">Post Office</a>
					</p>
					<div class="control is-expanded">
						<div class="select is-fullwidth">
							<select
								class="is-fullwidth"
								v-model="delivery.address.post_office">
								<optgroup
									v-if="delivery.address.parish"
									:label="
										'Post Offices in ' + delivery.address.parish
									">
									<option
										value=""
										disabled
										selected>
										- Select a {{ delivery.address.parish }} Post
										Office -
									</option>
									<option
										v-for="(
											postOffice, index
										) in getPostalPostOffices"
										:key="postOffice"
										:value="postOffice"
										v-html="postOffice"></option>
								</optgroup>
								<optgroup
									v-else
									label="Select a Parish to get a list of Post Offices">
									<option
										value=""
										disabled
										selected>
										- Please Select a Parish Above -
									</option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div v-if="delivery.choice == 'address'">
				<div
					v-if="!delivery.manually_entered_address"
					:key="'parish_input'"
					class="field has-addons">
					<p class="control">
						<a class="button is-static">Parish</a>
					</p>
					<div class="control is-expanded">
						<div class="select is-fullwidth">
							<select
								v-model="delivery.address.parish"
								class="is-fullwidth">
								<optgroup label="Parishes">
									<option
										value=""
										disabled
										selected
										v-html="'- Select your Parish -'"></option>
									<option
										v-for="(parish, index) in parishes"
										:key="parish"
										v-html="parish"
										:value="parish"></option>
								</optgroup>
							</select>
						</div>
					</div>
				</div>
				<div
					v-if="!delivery.manually_entered_address"
					:key="'district_input'"
					class="field has-addons">
					<p class="control">
						<a class="button is-static">District</a>
					</p>
					<p class="control is-expanded">
						<input
							v-model="delivery.address.district"
							class="input"
							type="text"
							placeholder="Enter your District" />
					</p>
				</div>
				<div
					v-if="!delivery.manually_entered_address"
					:key="'street_address_input'"
					class="field has-addons">
					<p class="control">
						<a class="button is-static">Street Address</a>
					</p>
					<p class="control is-expanded">
						<input
							v-model="delivery.address.street"
							class="input"
							type="text"
							placeholder="123 Road Name or Lot or Apartment" />
					</p>
				</div>
				<div
					v-if="delivery.manually_entered_address"
					:key="'manual_input'"
					class="field has-addons">
					<p class="control is-expanded">
						<textarea
							v-model="delivery.address.manual"
							class="textarea is-medium"
							:placeholder="'Example Address: \n\n83 Romao Street\n Montego Bay \nSt. James'"
							rows="5"></textarea>
					</p>
				</div>
				<b-field>
					<b-checkbox
						v-model="delivery.manually_entered_address"
						class="has-text-transform-none"
						>Do you want to enter your delivery address without
						assistance?</b-checkbox
					>
				</b-field>
			</div>
			<div class="mt-3">
				<span class="is-size-6">Delivery Fee:</span>
				<span
					:class="{ 'has-text-success': delivery_fee != null }"
					class="is-size-6"
					>{{ deliveryFeeDisplay }}</span
				>
			</div>
		</section>
	</div>
</template>
<style type="text/css" scoped></style>
<script type="text/javascript">
	import axios from "axios";
	axios.defaults.headers.post["X-CSRF-TOKEN"] = window.csrf_token;

	import Vue from "vue";
	import Buefy from "buefy";
	Vue.use(Buefy);

	export default {
		props: {
			products_prop: Array,
		},
		mounted() {
			this.fetchDeliveryFee();
		},
		data() {
			return {
				delivery_fee: null,
				delivery: {
					choice: "postal",
					address: {
						parish: "",
						district: "",
						street: "",
						post_office: "",
						manual: "",
					},
					manually_entered_address: false,
				},
				// prettier-ignore
				parishes: ["Clarendon", "Hanover", "Kingston & St. Andrew", "Manchester", "Portland", "St. Ann", "St. Catherine", "St. Elizabeth", "St. James", "St. Mary", "St. Thomas", "Trelawny", "Westmoreland"],
				// prettier-ignore
				postal:{
	                   "Clarendon":{"Aenon Town":{},"Beckford Kraal":{},"Borobridge":{},"Chapelton":{},"Colonel’s Ridge":{},"Crooked River":{},"Croft’s Hill":{},"Denbigh":{},"Four Paths":{},"Frankfield":{},"Grantham":{},"Hayes":{},"James Hill":{},"Kellits":{},"Lionel Town":{},"May Pen":{},"Milk River":{},"Mocho":{},"Porus":{},"Race Course":{},"Richmond Park":{},"Rock River":{},"Spaldings":{},"Smithville":{},"Thompson Town":{},"Toll Gate":{},"Wood Hall":{}},
	                   "Hanover":{"Cascade":{},"Dias":{},"Green Island":{},"Hopewell":{},"Jericho":{},"Lucea":{},"March Town":{},"Maryland":{},"Mount Peto":{},"Ramble":{},"Ramble":{},"Sandy Bay":{}},
	                   "Kingston & St. Andrew":{"Allman Town":{},"Bull Bay":{},"Castleton":{},"Central Sorting Office":{},"Constant Spring":{},"Cross Roads":{},"Dallas":{},"Denham Town":{},"Fontana Waterloo – Jamaica Post Shop":{},"General Post Office":{},"Gordon Town":{},"Grant’s Pen":{},"Hagley Park":{},"Half-Way-Tree":{},"Harbour View":{},"Jones Town":{},"New Kingston":{},"Lawrence Tavern":{},"Liguanea":{},"Mavis Bank":{},"Meadowbridge":{},"Mount James":{},"Mona":{},"Mount James":{},"Port Royal":{},"Red Hills":{},"St. Peters":{},"Stony Hill":{},"University of Technology P.O.":{},"Vineyard Town":{},"Western District":{},"Whitefield Town":{},"Windward Road":{},"Woodford":{}},
	                   "Manchester":{"Above Rocks":{},"Albert Town":{},"Banana Ground":{},"Christiana":{},"Coleyville":{},"Craighead":{},"Cross Keys":{},"Devon":{},"Ellen Street":{},"Harmons":{},"Harry Watch":{},"Hatfield":{},"Kirkvine":{},"Knockpatrick":{},"Lincoln":{},"Mandeville":{},"Mile Gully":{},"Old England":{},"Porus":{},"Pratville":{},"Santa Cruz":{},"Shooters Hill":{},"Troy":{},"Walderston":{},"Watson Hill":{},"Williamsfield":{}},
	                   "Portland":{"Balcarres":{},"Bangor Ridge":{},"Buff Bay":{},"Enfield":{},"Fairy Hill":{},"Fellowship":{},"Fruitful Vale":{},"Green Hill":{},"Hectors River":{},"Hope Bay":{},"Long Bay":{},"Manchioneal":{},"Moore Town":{},"Orange Bay":{},"Port Antonio":{},"Priestman’s River":{},"Skibo":{},"Swift River":{},"Windsor Castle":{}},
	                   "St. Ann":{"Alexandria":{},"Bamboo":{},"Bensonton":{},"Borobridge":{},"Browns Town":{},"Bunkers Hill":{},"Calderwood":{},"Cave Valley":{},"Claremount":{},"Clarks Town":{},"Discovery Bay":{},"Duanvale":{},"Duncans":{},"Falmouth":{},"Jackson Town":{},"Lodge":{},"Moneague":{},"Ocho Rios":{},"Runaway Bay":{},"Sawyers":{},"Sherwood Content":{},"St. Ann’s Bay":{}," Walkerswood":{},"Wakefield":{}},
	                   "St. Catherine":{"Above Rocks":{},"Bartons":{},"Bellas Gate":{},"Bog Walk":{},"Browns Hall":{},"Bridgeport":{},"Bushy Park":{},"Crofts Hill":{},"Ewarton":{},"Greater Portmore":{},"Gregory Park":{},"Harkers Hall":{},"Kellits":{},"Linstead":{},"Lluidas Vale":{},"Moneague":{},"Old Harbour":{},"Old Harbour Bay":{},"Pear Tree Grove":{},"Point Hill":{},"Red Wood":{},"Riversdale":{},"Sligoville":{},"Spanish Town":{},"Troja":{},"Waterford":{}},
	                   "St. Elizabeth":{"Aberdeen":{},"Balaclava":{},"Black River":{},"Braes River":{},"Brighton":{},"Elderslie":{},"Fyffes Pen":{},"Giddy Hall":{},"Junction":{},"Lacovia":{},"Maggotty":{},"Malvern":{},"Middle Quarters":{},"Mountain Side":{},"Munroe College":{},"Nain":{},"New Market":{},"Pepper":{},"Rose Hall":{},"Santa Cruz":{},"Siloah":{},"Southfield":{},"Springfield":{},"Spur Tree":{},"Stonehenge":{},"Treasure Beach":{},"Watson Hill":{}},
	                   "St. James":{"Adelphi":{},"Anchovy":{},"Cambridge":{},"Catadupa":{},"Granville":{},"Hopeton":{},"Hopewell":{},"John’s Hall":{},"Little River":{},"Lottery":{},"Maroon Town":{},"Montego Bay 1":{},"Montego Bay 2":{},"Montpelier":{},"Mount Horeb":{},"Reading":{},"Salt Spring":{},"Sandy Bay":{},"Sign":{},"Stonehenge":{},"Welcome Hall":{},"Whitesands Beach":{}},
	                   "St. Mary":{"Albany":{},"Annotto Bay ":{},"Belfield":{},"Bonny Gate":{},"Boscobel":{},"Carron Hall":{},"Castleton":{},"Clonmel":{},"Free Hill":{},"Gayle":{},"Guys Hill":{},"Hampstead":{},"Highgate":{},"Islington":{},"Lodge":{},"Lucky Hill":{},"Ocho Rios":{},"Oracabessa":{},"Pembroke Hall":{},"Port Maria":{},"Retreat":{},"Richmond":{},"Tower Isle":{},"Union Hill":{},"Walkerswood":{},"Windsor Castle":{}},
	                   "St. Thomas":{"Balcarres":{},"Bath":{},"Buff Bay":{},"Dalvey":{},"Fruitful Vale":{},"Golden Grove":{},"Green Hill":{},"Hector’s River":{},"Hope Bay":{},"Long Bay":{},"Lyssons":{},"Manchioneal":{},"Moore Town":{},"Morant Bay":{},"Orange Bay":{},"Port Antonio":{},"Port Morant":{},"Priestman’s River":{},"Skibo":{},"Seaforth":{},"Swift River":{},"Trinityville":{},"Whitehorses":{},"Yallahs":{}},
	                   "Trelawny":{"Albert Town":{},"Bunkers Hill":{},"Clarks Town":{},"Duanvale":{},"Duncans":{},"Falmouth":{},"Hampden":{},"Jackson Town":{},"Lorrimers":{},"Sawyers":{},"Sherwood Content":{},"Troy":{},"Wakefield":{}},
	                   "Westmoreland":{"Beeston Spring":{},"Bethel Town":{},"Bluefields":{},"Cascade":{},"Cave":{},"Darliston":{},"Dias":{},"Frome":{},"Glenislay":{},"Grange Hill":{},"Green Island":{},"Jericho":{},"Little London":{},"Lucea":{},"March Town":{},"Maryland":{},"Mount Peto":{},"Negril":{},"Petersfield":{},"Ramble":{},"Savanna-la-mar":{},"Sheffeild":{},"Whitehouse":{}}
	               },
			};
		},
		computed: {
			getPostalParishes() {
				return Object.keys(this.postal).sort();
			},
			getPostalPostOffices() {
				if (this.delivery.address.parish == "") {
					return [];
				} else {
					return Object.keys(
						this.postal[this.delivery.address.parish]
					).sort();
				}
			},
			details() {
				return this.$data.delivery;
			},
			deliveryChoice() {
				return this.delivery.choice;
			},
			deliveryFeeDisplay() {
				if (this.delivery_fee == null) {
					return "Enter Delivery Details to see cost of Delivery";
				} else {
					return `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(this.delivery_fee.fee)} ${this.delivery_fee.currency}`;
				}
			},
		},
		methods: {
			fetchDeliveryFee() {
				let method = "";
				if (this.delivery.choice == "postal") {
					method = "POST";
				} else if (this.delivery.choice == "address") {
					method = "ADDRESS";
				}

				let _this = this;
				axios
					.post(
						`${_this.$store.state.origin}/delivery_fee`, //url
						{ method, products: _this.products_prop }, //payload
						{ headers: { "Content-Type": "application/json" } } //configuration
					)
					.then(function (res) {
						console.log("Fetched Delivery Fee");
						console.log(res.data);
						_this.$emit("fetchedDeliveryFee", res.data);
						_this.delivery_fee = res.data;
					})
					.catch((error) => {
						_this.delivery_fee = null;
					})
					.then(function (res) {});
			},
		},
		watch: {
			products_prop: {
				deep: true,
				handler(newValue, oldValue) {
					console.log("Products Prop Change");
					this.fetchDeliveryFee();
				},
			},
			deliveryChoice() {
				console.log("Products Prop Change");
				this.fetchDeliveryFee();
			},
		},
	};
</script>
