<template>
	<div class="navbar-item px-0 pr-2">
		<div
			data-tippy-id="searchButton"
			class="is-clickable columns m-0 is-vcentered"
			style="margin-right: 1.5rem">
			<div class="column is-narrow m-0 p-0 pt-1">
				<i
					class="fa-solid fa-magnifying-glass is-size-5"
					style="cursor: pointer"></i>
			</div>
		</div>

		<div
			ref="tippy_content"
			class="is-hidden p-0">
			<div
				class="field has-addons"
				style="
					justify-content: center;
					min-width: 60vw;
					width: 70vw;
					max-width: 90vw;
				">
				<div
					class="control"
					style="width: 100%">
					<input
						class="input"
						type="text"
						placeholder="I'm Searching for..."
						required
						name="s" />
				</div>
				<div class="control">
					<button
						type="submit"
						class="button is-info">
						Search →
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	@import "./../../../../sass/app.scss";

	.is-clickable {
		cursor: pointer;
	}

	.is-hidden {
		display: none;
	}
</style>
<script type="text/javascript">
	import tippy from "tippy.js";
	import "tippy.js/dist/tippy.css";
	import "tippy.js/themes/light.css";
	import "tippy.js/animations/scale.css";
	import "tippy.js/animations/scale-subtle.css";

	export default {
		props: {
			prop_categories: Object,
			prop_market: String,
		},
		data() {
			return {
				tippy: null,
				category: null,
				categories: {
					Computers: {
						"Computer Parts": [
							"Monitors",
							"Mouse",
							"Mouse Pad",
							"Keyboard",
							"Fan",
							"Chargers",
						],
						"Computer Case": ["Plastic", "Enclosure"],
						Brands: [
							"Intel",
							"AMD",
							"Dell",
							"Asus",
							"Hewlett Packard (HP)",
						],
					},
					"Business & Industrial": {
						Healthcare: ["Dental Supplies", "Medical Lab Supplies"],
						Safety: [
							"Personal Protective Equipment (PPE)",
							"Fire Protection Equipment",
							"Surveillance & Alarm Equipment",
							"Public Sarety Equipment",
						],
					},
					School: {
						Textbooks: ["Past Papers", "Pre-Owned"],
					},
					"Mobile phones": {
						Accessories: ["Case", "Charger", "Pop Holder", "Stickers"],
						Brands: ["Samsung", "Motorola"],
					},
					Entertainment: {
						Books: [],
						Movies: [],
						Music: [
							"Pianos and Keyboards",
							"Guitars and Bases",
							"Audio Equipment",
							"Wind and Woodwind Instruments",
						],
					},
					Clothing: {
						"Women's Clothing": [
							"Dresses",
							"Tops",
							"Sleepwear",
							"Jacket, Vests, Sweaters",
							"Footwear",
							"Women's Hats",
							"Women's Belts",
							"",
						],
						"Men's Clothing": [],
						"Costume, Occasion & Specialized Clothing": [
							"Uniforms and Work Clothing",
							"Wedding and Formal Wear",
							"Costumes",
						],
					},
					Sports: {
						"Outdoor Sports": [
							"Golf Clubs and Equipment",
							"Skateboarding",
							"Cycling Equipment",
						],
						"Team Sports": ["Basket Ball", "Football", "Bowling"],
						"Indoor Games": [
							"Ballards",
							"Darts",
							"Table Tennis",
							"Board Games",
							"Pool",
							"Game Machines",
						],
					},
					Restaurants: {
						asd: ["asd"],
					},
					Pets: {
						sdf: ["asdf"],
					},
					Exercise: {
						sdf: ["ds"],
					},
					Vehicle: {
						"Auto Parts": ["Car & Truck Parts", "Motorcycle Parts"],
						Technology: ["GPS & Security Devices", "In-Car Technology"],
					},
					"Cosmetics & Perfumes": {
						asd: ["assdfd"],
					},
					Jewelry: {
						asd: ["asd"],
					},
					Children: {
						sa: ["asd"],
					},
					Furniture: {
						"Baby & Care-giving": ["Crib"],
					},
					Household: {
						Repairs: ["Tools"],
						"Art and Collectibles": ["asd", "asdf"],
						Paint: ["aa", "bb"],
					},
					"Health & Beauty": {
						sd: ["ad", "adsf", "asd"],
					},
				},
			};
		},
		mounted() {
			let _this = this;
			this.tippy = tippy("[data-tippy-id='searchButton']", {
				theme: "light",
				allowHTML: true,
				placement: "bottom",
				content: this.$refs.tippy_content.innerHTML,
				animation: "scale-subtle",
				trigger: "click",
				interactive: true,
				maxWidth: _this.tippyMaxWidth,
				onShow(instance) {
					// Add p-0 to tippy-content element
					instance.popper.children[0].children[0].classList.add("p-0");
					this.windowHeight = window.innerHeight;
				},
			})[0]; //[0] is needed since an array is returned
		},
		computed: {
			tippyMaxWidth() {
				return 650;
			},
			action() {
				return this.$props.prop_market;
			},
			computedCategories() {
				let cg = {};
				this.$props.prop_categories
					? (cg = JSON.parse(this.$props.prop_categories))
					: (cg = this.$data.categories);
				return cg;
			},
			selectedCategory() {
				return this.computedCategories[this.getCategory];
			},
			getCategory() {
				if (this.category) {
					return this.category;
				}
				return Object.keys(this.computedCategories)[0];
			},
		},
		methods: {
			loading() {
				this.$refs.submit_button.classList.add("is-loading");
			},
			setSelectedCategory(value) {
				this.category = value;
			},
		},
	};
</script>
