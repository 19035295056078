import { render, staticRenderFns } from "./QuickPurchase.vue?vue&type=template&id=47148bcb&scoped=true&"
import script from "./QuickPurchase.vue?vue&type=script&lang=js&"
export * from "./QuickPurchase.vue?vue&type=script&lang=js&"
import style0 from "./QuickPurchase.vue?vue&type=style&index=0&id=47148bcb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47148bcb",
  null
  
)

export default component.exports