<template>
	<form
		:action="action"
		method="GET">
		<div
			class=""
			style="">
			<div
				class="field has-addons"
				style="
					justify-content: center;
					border: solid #714dd2 2px;
					border-radius: 5px;
					display: flex;
				">
				<div class="control">
					<span
						class="select"
						style="font-size: 0.85rem">
						<select
							name="category"
							style="
								max-width: 8rem;
								border: solid transparent 0px;
								border-radius: 5px;
							">
							<option
								selected
								value="">
								All Categories
							</option>
							<option
								v-for="(category, index) in computedCategories"
								:value="index.toLowerCase()">
								{{ index.toUpperCase() }}
							</option>
						</select>
					</span>
				</div>
				<div
					class="control"
					style="
						border: 0px solid transparent;
						display: inline-flex;
						width: 3rem;
						flex-shrink: 1;
						flex-grow: 1;
					">
					<input
						@keyup.enter="loading"
						style="
							border: 0px solid transparent;
							font-size: 0.85rem;
							flex-basis: 3rem;
							flex-grow: 1;
						"
						placeholder="I'm Searching for..."
						required
						ref="search_bar"
						autocomplete="off"
						class="input form-control pl-2"
						name="s"
						type="text" />
				</div>
				<div class="control">
					<button
						ref="submit_button"
						@click="loading"
						class="button is-primary px-5"
						style="border-radius: 0px; font-size: 0.85rem"
						type="submit"
						title="submit-button">
						<i class="fa fa-magnifying-glass"></i>
					</button>
				</div>
			</div>
		</div>
	</form>
</template>
<style scoped></style>
<script>
	export default {
		props: {
			prop_categories: Object,
			prop_market: String,
		},
		data() {
			return {
				category: null,
				categories: {
					Computers: {
						"Computer Parts": [
							"Monitors",
							"Mouse",
							"Mouse Pad",
							"Keyboard",
							"Fan",
							"Chargers",
						],
						"Computer Case": ["Plastic", "Enclosure"],
						Brands: [
							"Intel",
							"AMD",
							"Dell",
							"Asus",
							"Hewlett Packard (HP)",
						],
					},
					"Business & Industrial": {
						Healthcare: ["Dental Supplies", "Medical Lab Supplies"],
						Safety: [
							"Personal Protective Equipment (PPE)",
							"Fire Protection Equipment",
							"Surveillance & Alarm Equipment",
							"Public Sarety Equipment",
						],
					},
					School: {
						Textbooks: ["Past Papers", "Pre-Owned"],
					},
					"Mobile phones": {
						Accessories: ["Case", "Charger", "Pop Holder", "Stickers"],
						Brands: ["Samsung", "Motorola"],
					},
					Entertainment: {
						Books: [],
						Movies: [],
						Music: [
							"Pianos and Keyboards",
							"Guitars and Bases",
							"Audio Equipment",
							"Wind and Woodwind Instruments",
						],
					},
					Clothing: {
						"Women's Clothing": [
							"Dresses",
							"Tops",
							"Sleepwear",
							"Jacket, Vests, Sweaters",
							"Footwear",
							"Women's Hats",
							"Women's Belts",
							"",
						],
						"Men's Clothing": [],
						"Costume, Occasion & Specialized Clothing": [
							"Uniforms and Work Clothing",
							"Wedding and Formal Wear",
							"Costumes",
						],
					},
					Sports: {
						"Outdoor Sports": [
							"Golf Clubs and Equipment",
							"Skateboarding",
							"Cycling Equipment",
						],
						"Team Sports": ["Basket Ball", "Football", "Bowling"],
						"Indoor Games": [
							"Ballards",
							"Darts",
							"Table Tennis",
							"Board Games",
							"Pool",
							"Game Machines",
						],
					},
					Restaurants: {
						asd: ["asd"],
					},
					Pets: {
						sdf: ["asdf"],
					},
					Exercise: {
						sdf: ["ds"],
					},
					Vehicle: {
						"Auto Parts": ["Car & Truck Parts", "Motorcycle Parts"],
						Technology: ["GPS & Security Devices", "In-Car Technology"],
					},
					"Cosmetics & Perfumes": {
						asd: ["assdfd"],
					},
					Jewelry: {
						asd: ["asd"],
					},
					Children: {
						sa: ["asd"],
					},
					Furniture: {
						"Baby & Care-giving": ["Crib"],
					},
					Household: {
						Repairs: ["Tools"],
						"Art and Collectibles": ["asd", "asdf"],
						Paint: ["aa", "bb"],
					},
					"Health & Beauty": {
						sd: ["ad", "adsf", "asd"],
					},
				},
			};
		},
		mounted() {},
		computed: {
			action() {
				return this.$props.prop_market;
			},
			computedCategories() {
				let cg = {};
				this.$props.prop_categories
					? (cg = JSON.parse(this.$props.prop_categories))
					: (cg = this.$data.categories);
				return cg;
			},
			selectedCategory() {
				return this.computedCategories[this.getCategory];
			},
			getCategory() {
				if (this.category) {
					return this.category;
				}
				return Object.keys(this.computedCategories)[0];
			},
		},
		methods: {
			loading() {
				this.$refs.submit_button.classList.add("is-loading");
			},
			setSelectedCategory(value) {
				this.category = value;
			},
		},
	};
	/*
<div data-v-b007a1cc="" class="field has-addons" style="justify-content: center; border: 2px solid rgb(113, 77, 210); border-radius: 5px;display: flex;">
   <div data-v-b007a1cc="" class="control">
      <span data-v-b007a1cc="" class="select" style="font-size: 0.85rem;">
         <select data-v-b007a1cc="" name="category" style="max-width: 8rem; border: 0px solid transparent; border-radius: 5px;">
            <option data-v-b007a1cc="" selected="selected" value="">
							All Categories
						</option> <option data-v-b007a1cc="" value="computers">
							COMPUTERS
						</option><option data-v-b007a1cc="" value="business &amp; industrial">
							BUSINESS &amp; INDUSTRIAL
						</option><option data-v-b007a1cc="" value="school">
							SCHOOL
						</option><option data-v-b007a1cc="" value="mobile phones">
							MOBILE PHONES
						</option><option data-v-b007a1cc="" value="entertainment">
							ENTERTAINMENT
						</option><option data-v-b007a1cc="" value="clothing">
							CLOTHING
						</option><option data-v-b007a1cc="" value="sports">
							SPORTS
						</option><option data-v-b007a1cc="" value="restaurants">
							RESTAURANTS
						</option><option data-v-b007a1cc="" value="pets">
							PETS
						</option><option data-v-b007a1cc="" value="exercise">
							EXERCISE
						</option><option data-v-b007a1cc="" value="vehicle">
							VEHICLE
						</option><option data-v-b007a1cc="" value="cosmetics &amp; perfumes">
							COSMETICS &amp; PERFUMES
						</option><option data-v-b007a1cc="" value="jewelry">
							JEWELRY
						</option><option data-v-b007a1cc="" value="children">
							CHILDREN
						</option><option data-v-b007a1cc="" value="furniture">
							FURNITURE
						</option><option data-v-b007a1cc="" value="household">
							HOUSEHOLD
						</option><option data-v-b007a1cc="" value="health &amp; beauty">
							HEALTH &amp; BEAUTY
						</option></select>
                  </span></div>
                  <div data-v-b007a1cc="" class="control" style="border: 0px solid transparent;display: inline-flex;width: 20px;flex-shrink: 1;flex-grow: 1;">
                     <input data-v-b007a1cc="" placeholder="I'm Searching for..." required="required" autocomplete="off" name="s" type="text" class="input form-control pl-2" style="border: 0px solid transparent; font-size: 0.85rem;flex-basis: 3rem;flex-grow: 1;"></div> <div data-v-b007a1cc="" class="control"><button data-v-b007a1cc="" type="submit" title="submit-button" class="button is-primary px-5" style="border-radius: 0px; font-size: 0.85rem;"><svg data-v-b007a1cc="" class="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg><!-- <i data-v-b007a1cc="" class="fa fa-magnifying-glass"></i> Font Awesome fontawesome.com --></button></div></div>
*/
</script>
