<template>
	<div class="navbar-item px-0">
		<div
			@mouseover="setActiveShoppingCart"
			@click="setActiveShoppingCart"
			:data-tippy-id="uniqueCardContentIdentifier"
			class="is-clickable">
			<div class="columns is-mobile is-vcentered p-0 m-0">
				<div
					class="column is-narrow p-0 my-0 ml-2 mx-0-mobile"
					:class="{ 'mr-4': cartLength > 0, 'mr-1': cartLength == 0 }">
					<div>
						<i class="fa-solid fa-cart-shopping is-size-5 pr-1"></i>
						<span
							v-show="cartLength > 0"
							class="is-size-7 is-hidden-mobile has-text-white has-background-info"
							style="
								position: absolute;
								top: 1px;
								left: 27px;
								border-radius: 100px;
								height: 1.4rem;
								width: 1.4rem;
								display: inline-block;
								text-align: center;
								line-height: 1.45rem;
								font-weight: bold;
							"
							v-html="cartLength"></span>
					</div>
				</div>
				<div class="column m-0 p-0 is-hidden-mobile">
					<div class="columns is-mobile is-multiline m-0 p-0">
						<div
							class="column is-narrow m-0 p-0"
							style="">
							<p style="line-height: 1rem">
								<span
									class="has-text-weight-bold"
									style="font-size: 0.9rem">
									Shopping Cart
								</span>
								<br />
								<span
									class="is-size-7"
									v-html="cartCost">
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="showCartContentContainer"
			ref="cartContentContainer"
			class="is-hidden">
			<div
				ref="cartContent"
				class="m-0 p-0">
				<div
					:data-ref="`scroll-box-${uniqueCardContentIdentifier}`"
					class="columns m-0 p-0 is-multiline"
					:style="{
						'min-width': '25rem',
						'max-height': maxCartWindowHeight + 'px',
						'overflow-y': 'auto',
					}">
					<div
						v-if="cartLength == 0"
						class="column is-8 is-offset-2 has-text-grey">
						<p class="is-size-5 has-text-centered p-2">
							Your Cart is Empty
						</p>
						<p class="is-size-6 has-text-centered">
							Items you add to your cart will appear here
						</p>
					</div>
					<div
						v-for="(item, index) in cartArray"
						class="column m-0 p-0 is-full">
						<div class="columns is-mobile box m-0 p-0 is-vcentered">
							<div class="column is-4">
								<figure class="image is-square">
									<img
										:src="image(item)"
										alt=""
										style="max-height: none; object-fit: cover" />
								</figure>
							</div>
							<div class="column is-8">
								<div class="columns">
									<div class="column m-0 p-0">
										<p class="is-size-6">
											{{
												quantity(item.choices) + " × " + item.name
											}}
										</p>
										<p
											class="is-size-6 has-text-success"
											v-html="price(item)"></p>
										<p
											class="is-size-7"
											v-html="choices(item.choices)"></p>
										<p class="is-size-6 has-text-info pt-2">
											<a
												:href="item.link"
												target="_blank"
												>View Product
												<i
													style="margin-bottom: 1px"
													class="fas fa-xs fa-external-link-alt"></i
											></a>
										</p>
									</div>
									<div class="column is-narrow">
										<!-- TODO: Before Adding or Subtracting Products Make sure that the store actually has that amount in storage. -->
										<!-- Increment Product -->
										<span
											class="icon has-text-success"
											style="cursor: pointer"
											:index="index"
											onclick="window.app.shoppingCart.incrementProduct(this.getAttribute('index'))">
											<span class="fa-stack fa-sm">
												<i
													class="fas fa-circle fa-stack-2x"
													style="vertical-align: middle"></i>
												<i
													class="fas fa-plus fa-stack-1x fa-inverse"
													style="
														vertical-align: middle;
														scale: 0.55;
													"></i>
											</span>
										</span>

										<!-- Decrement Product -->
										<span
											class="icon"
											:class="{
												'is-invisible': quantity(item.choices) == 1,
											}"
											style="cursor: pointer"
											:index="index"
											onclick="window.app.shoppingCart.decrementProduct(this.getAttribute('index'))">
											<span class="fa-stack fa-sm">
												<i
													class="fas fa-circle fa-stack-2x"
													style="vertical-align: middle"></i>
												<i
													class="fas fa-minus fa-stack-1x fa-inverse"
													style="
														vertical-align: middle;
														scale: 0.55;
													"></i>
											</span>
										</span>

										<span
											:index="index"
											onclick="window.app.shoppingCart.removeFromCart(this.getAttribute('index'))"
											class="tag is-grey is-rounded is-delete mt-1"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="columns m-0 p-0 is-multiline width-100">
					<div
						v-if="cartLength"
						class="column is-full">
						<button
							key="enabled"
							onclick="window.app.shoppingCart.checkout()"
							class="button is-success is-large width-100">
							CHECKOUT
						</button>
					</div>
					<div
						v-else
						class="column is-full">
						<button
							key="disabled"
							disabled
							class="button is-success is-large width-100">
							CHECKOUT
						</button>
					</div>
				</div>
			</div>
		</div>

		<quick-purchase
			ref="quickPurchase"
			:endpoint="this.$props.endpoint"
			:products_prop="this.cartArray"
			:clear_cart="true"></quick-purchase>
		<purchase-modal
			ref="purchaseModal"
			:endpoint="this.$props.endpoint"
			:products_prop="this.cartArray"
			:clear_cart="true"
			:sign_up_link_prop="this.$props.sign_up_link_prop"
			:login_link_prop="this.$props.login_link_prop"></purchase-modal>
	</div>
</template>
<style lang="scss" scoped>
	@import "./../../../../sass/app.scss";

	.width-100 {
		width: 100%;
	}
</style>
<script type="text/javascript">
	import Vue from "vue";
	import Buefy from "buefy";
	Vue.use(Buefy);

	import tippy from "tippy.js";
	import "tippy.js/dist/tippy.css";
	import "tippy.js/themes/light.css";
	import "tippy.js/animations/scale.css";
	import "tippy.js/animations/scale-subtle.css";
	import PurchaseModal from "../PurchaseInterfaces/PurchaseModal.vue";
	import QuickPurchase from "../PurchaseInterfaces/QuickPurchase.vue";

	export default {
		components: {
			PurchaseModal,
			QuickPurchase,
		},
		props: ["sign_up_link_prop", "login_link_prop", "endpoint"],
		data() {
			return {
				isTippyVisible: false,
				cartScrollTop: 0,
				showCartContentContainer: true,
				cartValue: window.localStorage.getItem("cart"),
				windowHeight: window.innerHeight,
				cartTippy: null,
				uniqueCardContentIdentifier: this.createUniqueIdentifier(),
			};
		},
		mounted() {
			let _this = this;
			if (window.app == undefined) {
				window.app = {};
			}
			try {
				JSON.parse(window.localStorage.getItem("cart"));
			} catch (error) {
				window.localStorage.setItem("cart", "[]");
				console.log(error);
			}
			this.cartTippy = tippy(
				`[data-tippy-id='${this.uniqueCardContentIdentifier}']`,
				{
					theme: "light",
					allowHTML: true,
					placement: "bottom",
					content: _this.$refs.cartContent.innerHTML,
					animation: "scale-subtle",
					trigger: "click",
					interactive: true,
					maxWidth: 500,
					allowHTML: true,
					onShow(instance) {
						_this.isTippyVisible = true;
						_this.cartScrollTop = 0;
						_this.$nextTick(() => {
							instance.setContent(_this.$refs.cartContent.innerHTML);
							_this.windowHeight = window.innerHeight;
						});
					},
					onHide(instance) {
						_this.isTippyVisible = false;
					},
				}
			)[0]; //[0] is needed since an array is returned
			setInterval(function () {
				_this.updateCart();
			}, 400);
		},
		computed: {
			cartArray() {
				let _this = this;
				let a = "[]";
				try {
					a = JSON.parse(_this.cartValue).reverse();
				} catch (error) {
					a = [];
				}
				return a;
			},
			cartLength() {
				if (this.cartArray != null && this.cartArray.length >= 0) {
					return this.cartArray.length;
				}
				return 0;
			},
			maxCartWindowHeight() {
				return Math.floor(this.windowHeight * 0.55);
			},
			cartCost() {
				let cost = 0;
				for (let counter = 0; counter < this.cartArray.length; counter++) {
					cost +=
						parseFloat(this.cartArray[counter].pricing.current) *
						this.quantity(this.cartArray[counter].choices);
				}
				return (
					new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(Math.round(cost * 100) / 100) +
					" " +
					"JMD"
				);
			},
		},
		methods: {
			setActiveShoppingCart() {
				window.app.shoppingCart = this;
			},
			createUniqueIdentifier() {
				// Unique Identifier is used for each unique Tippy ID
				return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
					/[xy]/g,
					function (c) {
						let r = (Math.random() * 16) | 0,
							v = c == "x" ? r : (r & 0x3) | 0x8;
						return v.toString(16);
					}
				);
			},
			image(item) {
				let link = "";
				if (item.images[0]) {
					link = window.location.origin + item.images[0];
				}
				return link;
			},
			update() {
				let _this = this;
				let cartArray = [];
				try {
					cartArray = JSON.parse(window.localStorage.getItem("cart"));
				} catch (error) {
					cartArray = [];
				}
			},
			getCartScrollTop() {
				this.showCartContentContainer = false;
				this.$nextTick(() => {
					let tippyContentElement = document.querySelector(
						`[data-ref="scroll-box-${this.uniqueCardContentIdentifier}"]`
					);
					if (tippyContentElement) {
						this.cartScrollTop = tippyContentElement.scrollTop;
					}
					this.showCartContentContainer = true;
				});
			},
			setCartScrollTop() {
				this.showCartContentContainer = false;
				this.$nextTick(() => {
					let tippyContentElement = document.querySelector(
						`[data-ref="scroll-box-${this.uniqueCardContentIdentifier}"]`
					);
					if (tippyContentElement) {
						tippyContentElement.scrollTop = this.cartScrollTop;
					}
					this.showCartContentContainer = true;
				});
			},
			updateCart() {
				this.cartValue = window.localStorage.getItem("cart");
				// cartValue is required for variables like cartLength
				// the innerHTML scrollTop is relevant only if the Tooltip is being shown
				if (!this.isTippyVisible) {
					return;
				}
				this.getCartScrollTop();
				this.cartTippy.setContent(this.$refs.cartContent.innerHTML);
				this.setCartScrollTop();
			},
			removeFromCart(index) {
				let tempCart = JSON.parse(
					window.localStorage.getItem("cart")
				).reverse();
				let product = tempCart[index];
				tempCart.length > 1 ? tempCart.splice(index, 1) : tempCart.pop();
				console.log(tempCart);
				console.log(index);
				window.localStorage.setItem(
					"cart",
					JSON.stringify(tempCart.reverse())
				);
				let _this = this;
				setTimeout(() => {
					_this.cartTippy.setContent(_this.$refs.cartContent.innerHTML);
					_this.setCartScrollTop();
					_this.$buefy.notification.open({
						message: `Removed "${product.name}" from cart!`,
						type: "is-info",
						position: "is-top",
						pauseOnHover: true,
						progressBar: true,
						duration: 4000,
					});
				}, 300);
			},
			incrementProduct(index) {
				let _this = this;
				let tempCart = JSON.parse(
					window.localStorage.getItem("cart")
				).reverse();
				if (typeof tempCart[index] != "undefined") {
					let choices = tempCart[index].choices;
					for (let counter = 0; counter < choices.length; counter++) {
						if (choices[counter].choice == "quantity") {
							choices[counter].value = (
								parseInt(choices[counter].value) + 1
							).toString();
							tempCart[index].choices = choices;
							window.localStorage.setItem(
								"cart",
								JSON.stringify(tempCart.reverse())
							);
							setTimeout(function () {
								_this.cartTippy.setContent(
									_this.$refs.cartContent.innerHTML
								);
								// If the scrollTop is not set the cart content will 'snap' back to the top
								_this.setCartScrollTop();
							}, 350);
							break;
						}
					}
				}
			},
			decrementProduct(index) {
				let _this = this;
				let tempCart = JSON.parse(
					window.localStorage.getItem("cart")
				).reverse();
				if (typeof tempCart[index] != "undefined") {
					let choices = tempCart[index].choices;
					for (let counter = 0; counter < choices.length; counter++) {
						if (choices[counter].choice == "quantity") {
							if (parseInt(choices[counter].value) > 1) {
								choices[counter].value = (
									parseInt(choices[counter].value) - 1
								).toString();
							} else {
								choices[counter].value = "1";
							}
							tempCart[index].choices = choices;
							window.localStorage.setItem(
								"cart",
								JSON.stringify(tempCart.reverse())
							);
							setTimeout(function () {
								_this.cartTippy.setContent(
									_this.$refs.cartContent.innerHTML
								);
								// If the scrollTop is not set the cart content will 'snap' back to the top
								_this.setCartScrollTop();
							}, 350);
							break;
						}
					}
				}
			},
			choices(choicesArray) {
				if (choicesArray == undefined) {
					return "";
				}
				if (choicesArray.length <= 0) {
					return "";
				} //If there are no choices return an empty string
				if (choicesArray.length == 1) {
					return "";
				} //The last element is quantity which is handled by a quantity method
				if (choicesArray.length >= 2) {
					let choices_string = "";
					for (
						let counter = 0, count = choicesArray.length - 2;
						counter < count;
						counter++
					) {
						if (
							!!choicesArray[counter] &&
							choicesArray[counter].value != ""
						) {
							choices_string += choicesArray[counter].value;
						}
						if (
							!!choicesArray[counter + 1] &&
							choicesArray[counter + 1].value != undefined &&
							choicesArray[counter + 1].value != "" &&
							choicesArray[counter + 1].name != "quantity"
						) {
							choices_string += "&nbsp;•&nbsp;";
						}
					}
					// Don't want the last choice to have • at the end
					if (
						choicesArray[choicesArray.length - 2].value !== null &&
						choicesArray[choicesArray.length - 2].value !== undefined
					) {
						choices_string += choicesArray[choicesArray.length - 2].value;
					}
					return choices_string;
				}
			},
			quantity(choicesArray) {
				if (choicesArray == undefined) {
					return 0;
				}
				if (choicesArray.length == 0) {
					return 1;
				} //This case would likely be a single product, thus no quantity for the user to select.
				else {
					for (
						let counter = 0, count = choicesArray.length;
						counter < count;
						counter++
					) {
						if (choicesArray[counter].choice == "quantity") {
							return choicesArray[counter].value;
						}
					}
				}
				return 0;
				//if(choicesArray.length == 1){return choicesArray[0]}//This would be in the case where there is only quantity of the product to fill out.
			},
			checkout() {
				this.cartTippy.hide();
				//window.sessionStorage.setItem('logged_in', true | false) is in the main & dash view to tell if a user is logged in or not
				if (JSON.parse(window.sessionStorage.getItem("logged_in")).value) {
					this.$refs.quickPurchase.launchModal();
				} else {
					// This toggles the modal that appears when an unregistered user clicks "Buy Now"
					this.$refs.purchaseModal.launchModal();
				}
			},
			clearCart() {
				window.localStorage.setItem("cart", "[]");
			},
			price(item) {
				return (
					new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(item.pricing.current * this.quantity(item.choices)) +
					" " +
					item.pricing.currency
				);
			},
		},
	};
</script>
