<template>
	<div>
		<div class="field has-addons">
			<p class="control">
				<a class="button is-static">Line 1</a>
			</p>
			<p class="control is-expanded">
				<input
					v-model="billingAddress.line1"
					class="input"
					type="text"
					maxlength="30"
					pattern="^([^\S\t\n\r]|[-A-z0-9.,#]){0,30}$"
					placeholder="123 Road Name or Lot or Apartment" />
			</p>
		</div>
		<div class="field has-addons">
			<p class="control">
				<a class="button is-static">Line 2</a>
			</p>
			<p class="control is-expanded">
				<input
					v-model="billingAddress.line2"
					class="input"
					type="text"
					maxlength="50"
					pattern="^([^\S\t\n\r]|[-A-z0-9.,#]){0,30}$"
					placeholder="Line 2" />
			</p>
		</div>
		<div class="field has-addons">
			<p class="control">
				<a class="button is-static">City</a>
			</p>
			<p class="control is-expanded">
				<input
					v-model="billingAddress.city"
					class="input"
					type="text"
					maxlength="25"
					pattern="^([^\S\t\n\r]|[-A-z0-9.,#]){0,25}$"
					placeholder="Enter your city e.g. Kingston" />
			</p>
		</div>
		<!--  -->
		<div class="field has-addons">
			<p class="control">
				<a class="button is-static">Country</a>
			</p>
			<div class="control is-expanded">
				<div class="select is-fullwidth">
					<select
						class="is-fullwidth"
						v-model="billingAddress.country">
						<optgroup label="Countries">
							<option
								value=""
								disabled
								v-html="'- Select your Country -'"></option>
							<option value="JM">Jamaica</option>
							<option value="US">United States</option>
						</optgroup>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css" scoped>
	input:valid {
	}
	input:invalid {
		background-color: rgb(255, 190, 190);
	}
</style>
<script>
	export default {
		data() {
			return {
				billingAddress: {
					line1: null,
					line2: null,
					country: "JM",
					city: null,
				},
			};
		},
		methods: {
			details() {
				return this.billingAddress;
			},
		},
	};
</script>
