<template>
	<!--  -->
	<b-modal
		v-model="isPurchaseModalActive"
		:width="960"
		scroll="keep"
		:destroyOnHide="destroyOnHideValue"
		:canCancel="canCancelValue"
		:onCancel="cancelHandler">
		<div class="card">
			<div class="card-content">
				<div class="content">
					<div
						class="p-5 has-background-white"
						style="border-radius: 6px">
						<div class="columns is-multiline">
							<div class="column pb-2 pt-5 pl-5 pr-5 m-0 is-full">
								<h1 class="has-text-centered">Purchase Form</h1>
								<p class="is-size-5 has-text-centered">
									Fill out this form to complete your purchase and
									receive your receipt.
								</p>
							</div>
							<div class="column is-full pl-0 pr-0 pt-0 pb-2">
								<div class="columns is-centered is-vcentered">
									<div class="column p-0 is-4">
										<div class="field mt-2 mb-1 has-text-centered">
											<div
												class="control is-size-7 has-text-centered">
												<p class="m-1">
													Want to skip this form next time?
													<a
														:href="sign_up_link_prop"
														class="has-text-info"
														target="_blank"
														>Sign Up Now!</a
													>
												</p>
											</div>
										</div>
									</div>
									<div class="column p-0 is-4">
										<div class="field mt-2 mb-1 has-text-centered">
											<div
												class="control is-size-7 has-text-centered">
												<p class="m-1">
													Already have an account?
													<a
														:href="login_link_prop"
														class="has-text-info"
														>Login!</a
													>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								class="column pt-5 pl-5 pr-5 pb-5 is-7-desktop is-full-mobile has-text-left">
								<form
									method="post"
									action="#">
									<div class="field">
										<h1
											class="is-size-4"
											style="
												font-family: Arial;
												font-weight: normal;
											">
											Contact Information
										</h1>
									</div>
									<div class="field mt-5 is-horizontal">
										<div class="field-body">
											<div class="field">
												<label
													class="label"
													for="first_name"
													>Full Name</label
												>
												<input
													v-model="contact.full_name"
													class="input"
													type="text"
													placeholder="e.g. Johnathan Doberman"
													name="first_name"
													required="true"
													autocomplete="name" />
											</div>
										</div>
									</div>
									<div class="field mt-5">
										<label
											class="label"
											for="email"
											>Email</label
										>
										<div class="control">
											<input
												v-model="contact.email"
												ref="email_input"
												v-on:input="compareEmails"
												class="input"
												type="email"
												placeholder="e.g. johndoe@email.com"
												name="email"
												required="true"
												autocomplete="email" />
										</div>
									</div>
									<div class="field mt-5">
										<label
											class="label"
											for="confirm-email"
											>Confirm Email</label
										>
										<div class="control">
											<input
												ref="confirm_email_input"
												v-on:input="compareEmails"
												class="input"
												type="email"
												name="confirm-email"
												required="true" />
										</div>
									</div>
									<div class="field">
										<p
											ref="email_match"
											class="help is-hidden is-success is-size-6">
											Emails Match
										</p>
										<p
											ref="email_no_match"
											class="help is-hidden is-danger is-size-6">
											Emails don't Match
										</p>
										<p
											ref="email_invalid"
											class="help is-hidden is-danger is-size-6">
											Email entered is not valid
										</p>
									</div>
									<div class="field mt-5">
										<label
											class="label"
											for="confirm-email"
											>Phone Number&nbsp;&nbsp;<span
												class="has-text-grey"
												>(Recommended)</span
											></label
										>
										<phone-number-input
											:modelValue="contact.phone_number"
											@update:modelValue="
												(newValue) =>
													(contact.phone_number = newValue)
											" />
									</div>
									<div class="field">
										<p class="help has-text-grey is-size-7">
											For your convenience, we will send a message
											with information regarding your purchase to the
											number above. We will continue to notify you
											about the delivery status of your packages
											until they have been delivered.
										</p>
										<!-- <p class="help has-text-grey is-size-7">We will send a verification code to the above phone number. We will notify you by SMS about the delivery status of your packages. If you are a seller you will be notified on customer orders and important updates about your store. Message and data rates may apply.</p> -->
									</div>
									<div class="field mt-5 mb-3">
										<h1
											class="is-size-4 mb-4 pt-0"
											style="
												font-family: Arial;
												font-weight: normal;
											">
											Delivery Method
										</h1>
									</div>
									<delivery-input
										ref="deliveryInput"
										:products_prop="this.products"
										@fetchedDeliveryFee="
											(fee) => {
												deliveryFee = fee;
											}
										"></delivery-input>
								</form>
							</div>
							<div
								class="column pt-5 pl-5 pr-5 pb-5 is-5-desktop is-full-mobile has-text-left">
								<div class="field">
									<h1
										class="is-size-4"
										style="font-family: Arial; font-weight: normal">
										Payment Information
									</h1>
								</div>
								<div class="field mt-5 mb-5">
									<label
										class="label"
										for="confirm-email"
										v-html="
											this.products.length > 1
												? 'Your Products'
												: 'Your Product'
										"></label>
									<div
										v-for="(product, index) in products"
										class="control">
										<div
											class="columns box m-1 p-1 is-vcentered is-mobile">
											<div class="column is-4">
												<figure class="image is-square m-0">
													<img
														:src="image(product)"
														style="max-height: none"
														alt="" />
												</figure>
											</div>
											<div class="column is-8">
												<p class="is-size-6 m-0 p-0">
													{{
														quantity(product) +
														" × " +
														product.name
													}}
												</p>
												<p
													class="is-size-6 has-text-success m-0 p-0">
													{{ price(product) }}
												</p>
												<p
													class="is-size-6 mt-2"
													v-html="choices(product)"></p>
											</div>
										</div>
									</div>
									<div class="mt-4 mb-5">
										<table
											class="table is-narrow mb-0 has-text-centered-mobile"
											style="border: solid transparent 0px">
											<tr>
												<td
													class="m-0 p-0 has-text-grey-light"
													style="border: solid transparent 0px">
													<span
														class="is-size-6 has-text-gray-light mb-0">
														Product<span
															v-show="products.length > 1"
															>s</span
														>:
														<span class="has-text-grey-light">{{
															productTotalString
														}}</span>
													</span>
												</td>
											</tr>
											<tr>
												<td
													class="m-0 p-0 has-text-grey-light"
													style="border: solid transparent 0px">
													<span
														class="is-size-6 has-text-gray-light mb-0">
														Delivery:
													</span>
													<span class="has-text-grey-light">{{
														deliveryTotalString
													}}</span>
												</td>
											</tr>
										</table>
										<p
											class="is-size-5 mb-0 has-text-centered-mobile">
											Grand Total:<br class="is-hidden-tablet" />
											<span class="has-text-success">{{
												productAndDeliveryTotalString
											}}</span>
										</p>
									</div>
								</div>
								<payment-input
									ref="paymentInput"
									:total="productAndDeliveryTotal"></payment-input>
								<div class="field mt-4">
									<div class="control">
										<button
											@click="purchase"
											ref="purchaseButton"
											class="is-success button is-large width-100">
											Purchase&nbsp;&nbsp;&nbsp;<i
												class="fas fa-money-check-alt"></i>
										</button>
									</div>
								</div>
								<IframeModal
									ref="iframeModal"
									:iframe_srcdoc_prop="iframeContent"></IframeModal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
	<!--  -->
</template>
<style lang="scss" scoped>
	// @import "./../../../../sass/app.scss";
	h1 {
		text-transform: none;
	}

	* {
		text-transform: none;
		font-family: Arial, Helvetica;
	}

	.label {
		font-weight: normal;
		font-size: 0.9rem;
	}
</style>
<script type="text/javascript">
	import Vue from "vue";
	import Buefy from "buefy";
	import axios from "axios";
	import DeliveryInput from "./DeliveryInput.vue";
	import PaymentInput from "./PaymentInput.vue";
	import IframeModal from "./IframeModal.vue";
	import PhoneNumberInput from "../GeneralInputComponents/PhoneNumberInput.vue";
	axios.defaults.headers.post["X-CSRF-TOKEN"] = window.csrf_token;

	Vue.use(Buefy);
	export default {
		props: {
			products_prop: Array,
			product_prop: Array, // One product in array expected
			sign_up_link_prop: String,
			login_link_prop: String,
			endpoint: String,
			clear_cart: Boolean,
		},
		components: {
			DeliveryInput,
			PaymentInput,
			IframeModal,
			PhoneNumberInput,
		},
		data() {
			return {
				isPurchaseModalActive: false,
				choices_string: "",
				canCancelValue: ["escape", "outside", "x"],
				destroyOnHideValue: false,
				contact: {
					full_name: "",
					email: "",
					phone_number: "",
				},
				iframeContent: "",
				deliveryFee: null,
			};
		},
		computed: {
			currency() {
				if (this.products.currency) {
					return this.products.currency;
				} else {
					return "";
				}
			},
			isSingleProduct() {
				if (this.products.length == 1) {
					if (
						this.products[0] &&
						typeof this.products[0].price == "string"
					) {
						return true;
					}
				}
				return false;
			},
			products() {
				if (this.$props.products_prop) {
					return this.$props.products_prop;
				} else if (this.$props.product_prop) {
					return this.$props.product_prop;
				}
			},
			delivery() {
				if (!this.isPurchaseModalActive) {
					return null;
				}
				return this.$refs.deliveryInput.details;
			},
			payment() {
				if (!this.isPurchaseModalActive) {
					return null;
				}
				let p = new Object();
				p.method = this.$refs.paymentInput.method;
				p.details = this.$refs.paymentInput.details;
				return p;
			},
			productTotal() {
				let totals = new Map();

				if (this.products.length == 1) {
					if (
						this.products[0] &&
						typeof this.products[0].price == "string"
					) {
						totals.set(
							this.products[0].currency,
							parseFloat(this.products[0].price) *
								this.quantity(this.products[0])
						);
						return totals;
					}
				}
				// Totals is an array just in case multiple currencies are used.
				this.products.forEach((item, index, array) => {
					if (!totals.has(item.pricing.currency)) {
						totals.set(item.pricing.currency, 0);
					}
					let updatedTotal =
						//prettier-ignore
						totals.get(item.pricing.currency) + (item.pricing.current * this.quantity(item));
					totals.set(item.pricing.currency, updatedTotal);
				});
				return totals;
			},
			deliveryTotal() {
				//TODO: Get the number of stores so that the delivery fee for each can be calculated.
				let m = new Map();
				if (this.deliveryFee !== null) {
					m.set(this.deliveryFee.currency, this.deliveryFee.fee);
				}
				return m;
			},
			deliveryTotalString() {
				let s = "";
				this.deliveryTotal.forEach((value, key, map) => {
					s += `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value)} ${key}  `;
				});
				return s;
			},
			productTotalString() {
				let s = "";
				this.productTotal.forEach((value, key, map) => {
					s += `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value)} ${key}  `;
				});
				return s;
			},
			productAndDeliveryTotal() {
				let totals = new Map();
				this.productTotal.forEach((value, key, map) => {
					if (!totals.has(key)) {
						totals.set(key, 0);
					}
					let updatedTotal = totals.get(key) + value;
					totals.set(key, updatedTotal);
				});
				this.deliveryTotal.forEach((value, key, map) => {
					if (!totals.has(key)) {
						totals.set(key, 0);
					}
					let updatedTotal = totals.get(key) + value;
					totals.set(key, updatedTotal);
				});
				return totals;
			},
			productAndDeliveryTotalString() {
				let s = "";
				let counter = 0;
				this.productAndDeliveryTotal.forEach((value, key, map) => {
					counter++;

					s += `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value)} ${key}`;

					if (counter < map.size) {
						s += " + ";
					}
				});
				return s;
			},
		},
		methods: {
			compareEmails() {
				//Checking that length is greater than 5
				if (this.$refs.email_input.value.length < 7) {
					this.$refs.email_match.classList.add("is-hidden");
					this.$refs.email_no_match.classList.add("is-hidden");
					this.$refs.email_invalid.classList.add("is-hidden");
					return false;
				}
				if (!this.$refs.email_input.checkValidity()) {
					this.$refs.email_match.classList.add("is-hidden");
					this.$refs.email_no_match.classList.add("is-hidden");
					this.$refs.email_invalid.classList.remove("is-hidden");
					return false;
				}
				if (
					this.$refs.email_input.value ==
					this.$refs.confirm_email_input.value
				) {
					this.$refs.email_match.classList.remove("is-hidden");
					this.$refs.email_no_match.classList.add("is-hidden");
					this.$refs.email_invalid.classList.add("is-hidden");
					return true;
				} else {
					this.$refs.email_match.classList.add("is-hidden");
					this.$refs.email_no_match.classList.remove("is-hidden");
					this.$refs.email_invalid.classList.add("is-hidden");
					return false;
				}
			},
			details() {
				let products = [];
				if (this.products_prop) {
					products = this.products_prop;
				} else if (this.product_prop) {
					let product = this.product_prop;
					product[0].choices = this.$store.getters.choices;
					products = product;
				}
				return {
					method: "guest-purchase",
					contact: this.contact,
					delivery: this.delivery,
					payment: this.payment,
					products: products,
				};
			},
			form() {
				let products = [];
				if (this.products_prop) {
					products = this.products_prop;
				} else if (this.product_prop) {
					let product = this.product_prop;
					product[0].choices = this.$store.getters.choices;
					products = product;
				}
				let form = new FormData();
				form.append("method", "guest-purchase");
				form.append("contact", JSON.stringify(this.contact));
				form.append("delivery", JSON.stringify(this.delivery));
				form.append("payment", JSON.stringify(this.payment));
				form.append("products", JSON.stringify(products));
				return form;
			},
			purchase() {
				let _this = this;
				this.$refs.purchaseButton.classList.add("is-loading");
				this.$refs.purchaseButton.setAttribute("disabled", "disabled");
				let launchDelay = 1000,
					closeDelay = launchDelay - 500;
				switch (this.$refs.paymentInput.method) {
					case "Bank Card":
						// Visual pause for the user after clicking the purchase button
						_this.$refs.iframeModal.launchModal(launchDelay);
						break;
					case "Lynk":
						break;
				}

				axios
					.post(
						_this.endpoint, //url
						_this.details(), //payload
						{ headers: { "Content-Type": "application/json" } } //configuration
					)
					.then(function (response) {
						_this.$refs.purchaseButton.classList.remove("is-loading");
						_this.$refs.purchaseButton.removeAttribute("disabled");
						switch (_this.$refs.paymentInput.method) {
							case "Bank Card":
								_this.iframeContent = response.data;
								break;
							case "Lynk":
								_this.closeModal();
								_this.$buefy.notification.open({
									message:
										"<b>Transaction Successful!</b><br/>Your transaction was processed successful, please wait for us to message (email or text message) you regarding the status of your Lynk Payment. Expect a response within 24 hours",
									type: "is-success",
									position: "is-top",
									indefinite: true,
								});

								break;
						}
						// The iframe should now contain iframe content in response.data
						// the iFrameModal takes over the rest of the transaction.

						/*
		                    TODO: This is likely not where clearing cart should be done after
		                    implementing the Payment Gateway, make sure to move the code to clear
		                    the cart to a more appropriate location.

		                    A possible bug is the transaction failing and the items in the cart
		                    are cleared out improperly.

		                    Another possible bug is the server not getting the products in the
		                    cart to process because the cart has been cleared.
		                 */
						if (_this.$props.clear_cart) {
							window.app.shoppingCart.clearCart();
						}
					})
					.catch((error) => {
						console.error(error);
						_this.$refs.purchaseButton.classList.remove("is-loading");
						_this.$refs.purchaseButton.removeAttribute("disabled");
						_this.$refs.iframeModal.closeModal(closeDelay);
						_this.$buefy.dialog.alert({
							type: "is-danger",
							title: "Purchase Unsuccessful",
							hasIcon: true,
							message:
								"Your purchase was unsuccessful, please try again later. Ensure that your information has been entered correctly and that you are connected to the internet. If the problem persists please contact us.",
						});
						_this.$refs.paymentInput.clear();
					});
			},
			cancelHandler() {
				this.$refs.paymentInput.clear();
			},
			launchModal() {
				this.isPurchaseModalActive = true;
				// this.choices();
			},
			closeModal() {
				this.isPurchaseModalActive = false;
			},
			quantity(c) {
				let choicesArray = this.$store.getters.choices;
				if (this.products_prop) {
					choicesArray = c.choices;
				} else if (this.product_prop) {
					choicesArray = this.$store.getters.choices;
				}
				if (choicesArray == undefined) {
					return 0;
				}
				if (choicesArray.length == 0) {
					return 1;
				} //This case would likely be a single product, thus no quantity for the user to select.
				else {
					for (
						let counter = 0, count = choicesArray.length;
						counter < count;
						counter++
					) {
						if (choicesArray[counter].choice == "quantity") {
							return choicesArray[counter].value;
						}
					}
				}
				//if(choicesArray.length == 1){return choicesArray[0]}//This would be in the case where there is only quantity of the product to fill out.
			},
			choices(c) {
				let choicesArray = [];

				if (this.products_prop) {
					choicesArray = c.choices;
				} else if (this.product_prop) {
					choicesArray = this.$store.getters.choices;
				}

				if (choicesArray == undefined) {
					return "";
				}
				if (choicesArray.length <= 0) {
					return "";
				} //If there are no choices return an empty string
				if (choicesArray.length == 1) {
					return "";
				} //The last element is quantity which is handled by a quantity method
				if (choicesArray.length >= 2) {
					let choices_string = "";
					for (
						let counter = 0, count = choicesArray.length - 2;
						counter < count;
						counter++
					) {
						if (
							!!choicesArray[counter] &&
							choicesArray[counter].value != ""
						) {
							choices_string += choicesArray[counter].value;
						}
						if (
							!!choicesArray[counter + 1] &&
							choicesArray[counter + 1].value != undefined &&
							choicesArray[counter + 1].value != "" &&
							choicesArray[counter + 1].name != "quantity"
						) {
							choices_string += "&nbsp;•&nbsp;";
						}
					}
					// Don't want the last choice to have • at the end
					if (
						choicesArray[choicesArray.length - 2].value !== null &&
						choicesArray[choicesArray.length - 2].value !== undefined
					) {
						choices_string += choicesArray[choicesArray.length - 2].value;
					}
					this.choices_string = choices_string;
					return choices_string;
				}
			},
			image(item) {
				let link = "";
				if (item.images[0]) {
					link = window.location.origin + item.images[0];
				}
				return link;
			},
			price(item) {
				if (item.pricing) {
					return (
						new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(item.pricing.current * this.quantity(item)) +
						item.pricing.currency
					);
				} else {
					return new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(item.price * this.quantity(item));
				}
			},
		},
	};
</script>
