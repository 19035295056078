<template>
	<div>
		<p class="is-size-7 p-0 m-0">Your Cart</p>
		<p
			v-show="cartCost"
			class="p-0 m-0 has-text-weight-bold has-text-success"
			style="font-size: 0.8rem">
			{{ cartCost }}
		</p>
	</div>
</template>
<style></style>
<script>
	export default {
		data() {
			return {
				cartContentJSON: [],
			};
		},
		mounted() {
			let _this = this;
			setInterval(function () {
				_this.updateCart();
			}, 400);
		},
		computed: {
			cartContent() {
				let _this = this;
				let a = "[]";
				try {
					a = JSON.parse(_this.cartContentJSON).reverse();
				} catch (error) {
					a = [];
				}
				return a;
			},
			cartCost() {
				let cost = 0;
				for (
					let counter = 0;
					counter < this.cartContent.length;
					counter++
				) {
					cost += parseFloat(this.cartContent[counter].pricing.current);
				}
				return `${new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(cost)} JMD`;
			},
		},
		methods: {
			updateCart() {
				this.cartContentJSON = window.localStorage.getItem("cart");
			},
		},
	};
</script>
