<template>
	<div style="margin: 0px; padding: 0px">
		<!-- Start Wishlist Toggle -->
		<span
			@click="showWishlistSidebar"
			href="wishlist.html"
			class="wishlist-toggle wishlist"
			title="wishlist">
			<i class="d-icon-heart"></i>
		</span>
		<!-- End Wishlist Toggle -->
		<!-- Start Wishlist Dropdown -->
		<div
			ref="wishlist-dropdown"
			class="dropdown wishlist wishlist-dropdown off-canvas">
			<div class="canvas-overlay"></div>
			<div class="dropdown-box scrollable">
				<div class="canvas-header">
					<h4 class="canvas-title">Wishlist</h4>
					<a
						href="#"
						class="btn btn-dark btn-link btn-icon-right btn-close"
						>close<i class="d-icon-arrow-right"></i
						><span class="sr-only">wishlist</span></a
					>
				</div>
				<div class="products scrollable">
					<span
						v-if="wishlistItems.length == 0"
						class="is-block is-size-4 has-text-centered mt-3 mb-2">
						You have nothing in your wishlist.
					</span>
					<div
						v-for="(item, index) in wishlistItems"
						class="product product-wishlist">
						<figure class="product-media">
							<a :href="item.link">
								<img
									:src="image(item)"
									width="100"
									height="100"
									alt="product" />
							</a>
						</figure>
						<div class="product-detail">
							<a
								:href="item.link"
								class="product-name"
								>{{ item.name }}</a
							>
							<div class="price-box">
								<span
									class="product-price"
									v-html="
										'$' +
										item.pricing.current +
										' ' +
										item.pricing.currency
									"></span>
							</div>
						</div>
					</div>
					<!-- End of wishlist Product -->
				</div>
				<a
					:href="wishlist_link"
					class="btn btn-dark wishlist-btn mt-4"
					><span>Go To Wishlist</span></a
				>
				<!-- End of Products  -->
			</div>
			<!-- End Dropdown Box -->
		</div>
		<!-- End Wishlist Dropdown -->
	</div>
</template>
<style type="text/css" scoped></style>
<script>
	export default {
		props: {
			wishlist_link_prop: String,
		},
		data() {
			return {
				wishlistItems: [],
			};
		},
		computed: {
			logged_in() {
				return JSON.parse(window.sessionStorage.getItem("logged_in")).value;
			},
			wishlist_link() {
				return this.wishlist_link_prop;
			},
		},
		mounted() {},
		methods: {
			showWishlistSidebar() {
				this.getWishlistItems();
				this.$refs["wishlist-dropdown"].classList.add("opened");
			},
			getWishlistItems() {
				let _this = this;
				if (this.logged_in) {
					axios
						.post(this.wishlist_link, {
							method: "list",
						})
						.then(function (response) {
							console.log(response);
							console.log(response.data);
							_this.wishlistItems = response.data;
						})
						.catch(function (response) {})
						.then(function (response) {});
				} else {
					let _this = this;
					let wishlist = JSON.parse(
						window.localStorage.getItem("wishlist")
					);
					let promises = [];
					for (let counter = 0; counter < wishlist.length; counter++) {
						promises.push(axios.get("/api" + wishlist[counter]));
						console.log("/api" + wishlist[counter]);
					}
					console.log(promises);
					_this.wishlistItems = [];
					Promise.all(promises)
						.then(function (response) {
							for (
								let counter = 0;
								counter < response.length;
								counter++
							) {
								if (response[counter].data) {
									_this.wishlistItems.push(response[counter].data);
								}
							}
						})
						.catch(function (error) {})
						.then(function (response) {});
				}
			},
			image(item) {
				let link = "";
				if (item.images[0]) {
					link = window.location.origin + item.images[0];
				}
				console.log(link);
				return link;
			},
		},
	};
</script>
