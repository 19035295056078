<template>
	<!--  -->
	<b-modal
		v-model="isQuickPurchaseActive"
		:width="480"
		scroll="keep"
		:destroyOnHide="false"
		:canCancel="true"
		:onCancel="cancelHandler">
		<div class="card">
			<div
				class="w-100 is-size-4 has-text-centered p-3 has-background-white-ter has-text-weight-bold">
				Quick Purchase
			</div>
			<div class="card-content pt-1">
				<div class="content">
					<div class="field mt-3 mb-3">
						<div
							v-for="(product, index) in products"
							class="control">
							<div
								class="columns box mt-0 mb-1 mr-0 ml-0 p-1 is-vcentered is-mobile">
								<div class="column is-3">
									<figure class="image is-square m-0">
										<img
											:src="image(product)"
											style="max-height: none; object-fit: cover"
											alt="" />
									</figure>
								</div>
								<div class="column is-9">
									<p class="is-size-6 m-0 p-0">
										{{ quantity(product) + " × " + product.name }}
									</p>
									<p class="is-size-6 has-text-success m-0 p-0">
										{{ price(product) }}
									</p>
									<p
										class="is-size-7 mt-2"
										v-html="choices(product)"></p>
								</div>
							</div>
						</div>
						<div class="mt-4 mb-5 has-text-centered">
							<table
								class="table is-narrow mb-0 has-text-centered-mobile"
								style="border: solid transparent 0px">
								<tr>
									<td
										class="m-0 p-0 has-text-grey-light"
										style="border: solid transparent 0px">
										<span class="is-size-6 has-text-gray-light mb-0">
											Product<span v-show="products.length > 1"
												>s</span
											>:
											<span class="has-text-grey-light">{{
												productTotalString
											}}</span>
										</span>
									</td>
								</tr>
								<tr>
									<td
										class="m-0 p-0 has-text-grey-light"
										style="border: solid transparent 0px">
										<span class="is-size-6 has-text-gray-light mb-0">
											Delivery:
										</span>
										<span class="has-text-grey-light">{{
											deliveryTotalString
										}}</span>
									</td>
								</tr>
							</table>
							<p class="is-size-5 mb-0 has-text-centered-mobile">
								Grand Total:<br class="is-hidden-tablet" />
								<span class="has-text-success">{{
									productAndDeliveryTotalString
								}}</span>
							</p>
						</div>
					</div>
					<div class="field has-addons">
						<p class="control">
							<a class="button is-static"
								><i class="far fa-credit-card"></i
								>&nbsp;&nbsp;Payment&nbsp;</a
							>
						</p>
						<div class="control is-expanded">
							<div class="select is-fullwidth">
								<select
									ref="paymentValue"
									v-model="paymentInputValue"
									class="is-fullwidth">
									<optgroup
										v-if="savedPaymentMethods(method).length"
										v-for="(method, index) in paymentMethods"
										:label="method">
										<option
											v-for="(item, index) in savedPaymentMethods(
												method
											)"
											v-html="item"
											:value="item"
											:data-method="method"></option>
									</optgroup>
									<optgroup
										:label="
											paymentMethods.length > 0
												? '________________________________________'
												: 'No Saved Payment Methods'
										">
										<option
											value="*WALLET*"
											data-method="Wallet">
											Pay With Wallet
										</option>
										<option
											value=""
											data-method="manual">
											Enter Payment Information Manually
										</option>
										<option
											value="*"
											disabled>
											Select a Payment Method
										</option>
									</optgroup>
								</select>
							</div>
						</div>
					</div>
					<div
						v-if="isPaymentInputActive"
						class="mb-4">
						<payment-input
							ref="paymentInput"
							:total="productAndDeliveryTotal"
							class="pl-6 pr-6 pb-5 pt-5 mt-0 has-background-white-bis"
							style="border-radius: 7px"></payment-input>
						<b-checkbox
							v-model="save_payment_information"
							v-show="showSavePaymentMethod"
							class="p-2 has-text-transform-none"
							>Do you want to save this payment information to your
							account?<span
								class="has-text-grey"
								v-html="'&nbsp;(Saved on Purchase)'"></span
						></b-checkbox>
						<div
							v-if="save_payment_information"
							class="field has-addons p-2">
							<div class="control">
								<button
									class="button is-primary"
									disabled>
									Save as
								</button>
							</div>
							<div class="control is-expanded">
								<input
									ref="payment_name"
									class="input"
									type="text"
									placeholder="e.g. My Shopping Credit Card"
									name=""
									required="true" />
							</div>
						</div>
					</div>
					<div class="field has-addons">
						<p class="control">
							<a class="button is-static"
								><i class="fas fa-truck"></i
								>&nbsp;&nbsp;Delivery&nbsp;</a
							>
						</p>
						<div class="control is-expanded">
							<div class="select is-fullwidth">
								<select
									ref="deliveryValue"
									v-model="deliveryInputValue">
									<optgroup
										v-if="locations.length > 0"
										label="Saved Delivery Locations">
										<option
											v-for="(location, index) in locations"
											v-html="location"
											:value="location"></option>
									</optgroup>
									<optgroup
										:label="
											locations.length > 0
												? '________________________________________'
												: 'No Saved Delivery Locations'
										">
										<option value="">Enter Location Manually</option>
										<option
											value="*"
											disabled>
											Select a Delivery Location
										</option>
									</optgroup>
								</select>
							</div>
						</div>
					</div>
					<div
						v-show="
							deliveryInputValue != '' && deliveryInputValue != '*'
						">
						<span
							><b class="is-size-4">Delivery Fee:</b>
							<span
								:class="{ 'has-text-success': deliveryFee != null }"
								class="is-size-4"
								>{{ deliveryFeeDisplay }}</span
							></span
						>
					</div>
					<div
						v-if="isDeliveryInputActive"
						class="mb-4">
						<delivery-input
							ref="deliveryInput"
							@fetchedDeliveryFee="
								(fee) => {
									deliveryFee = fee;
								}
							"
							:products_prop="this.products"
							class="pl-6 pr-6 pb-5 pt-5 mt-0 has-background-white-bis"
							style="border-radius: 7px"></delivery-input>
						<b-checkbox
							v-model="save_delivery_information"
							class="p-2 has-text-transform-none"
							>Do you want to save this delivery information to your
							account?<span
								class="has-text-grey"
								v-html="'&nbsp;(Saved on Purchase)'"></span
						></b-checkbox>
						<div
							v-if="save_delivery_information"
							class="field has-addons p-2">
							<div class="control">
								<button
									class="button is-primary"
									disabled>
									Save As
								</button>
							</div>
							<div class="control is-expanded">
								<input
									ref="delivery_name"
									class="input"
									type="text"
									placeholder="e.g. My Home"
									name=""
									required="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="has-text-right p-2 has-background-white-ter">
				<button
					@click="cancel"
					class="button is-ghost m-1 has-text-weight-bold is-medium">
					Cancel</button
				><button
					ref="purchaseButton"
					@click="purchase"
					class="button is-success m-1 has-text-weight-bold is-medium">
					Purchase
				</button>
			</div>
			<IframeModal
				ref="iframeModal"
				:iframe_srcdoc_prop="iframeContent"></IframeModal>
		</div>
	</b-modal>
</template>
<style lang="scss" scoped>
	.is-hidden {
		visibility: hidden;
	}

	.w-100 {
		width: 100%;
	}

	.text-transform-none {
		text-transform: none;
	}
</style>
<script type="text/javascript">
	import Vue from "vue";
	import Buefy from "buefy";
	import axios from "axios";
	import DeliveryInput from "./DeliveryInput.vue";
	import PaymentInput from "./PaymentInput.vue";
	import IframeModal from "./IframeModal.vue";

	axios.defaults.headers.post["X-CSRF-TOKEN"] = window.csrf_token;

	Vue.use(Buefy);
	export default {
		components: {
			DeliveryInput,
			PaymentInput,
			IframeModal,
		},
		props: ["endpoint", "products_prop", "product_prop", "clear_cart"],
		data() {
			return {
				isMounted: false,
				isQuickPurchaseActive: false,
				save_payment_information: false,
				save_delivery_information: false,
				paymentValueElement: this.$refs.paymentValue,
				paymentInputData: null,
				showSavePaymentMethod: true,
				payment: {},
				locations: [],
				state: this.$store.state,
				paymentInputValue: "*",
				deliveryInputValue: "*",
				iframeContent: "",
				deliveryFee: null,
			};
		},
		mounted() {
			this.isMounted = true;
			if (JSON.parse(window.sessionStorage.getItem("logged_in")).value) {
				this.$store.dispatch("get_quick_purchase_information");
			}
		},
		computed: {
			isLoggedIn() {
				if (JSON.parse(window.sessionStorage.getItem("logged_in")).value) {
					return true;
				}
				return false;
			},
			products() {
				if (this.$props.products_prop) {
					return this.$props.products_prop;
				} else if (this.$props.product_prop) {
					return this.$props.product_prop;
				}
			},
			productTotal() {
				let totals = new Map();

				if (this.products.length == 1) {
					if (
						this.products[0] &&
						typeof this.products[0].price == "string"
					) {
						totals.set(
							this.products[0].currency,
							parseFloat(this.products[0].price) *
								this.quantity(this.products[0])
						);
						return totals;
					}
				}
				// Totals is an array just in case multiple currencies are used.
				this.products.forEach((item, index, array) => {
					if (!totals.has(item.pricing.currency)) {
						totals.set(item.pricing.currency, 0);
					}
					let updatedTotal =
						//prettier-ignore
						totals.get(item.pricing.currency) + (item.pricing.current * this.quantity(item));
					totals.set(item.pricing.currency, updatedTotal);
				});
				return totals;
			},
			deliveryTotal() {
				//TODO: Get the number of stores so that the delivery fee for each can be calculated.
				let m = new Map();
				if (this.deliveryFee !== null) {
					m.set(this.deliveryFee.currency, this.deliveryFee.fee);
				}
				return m;
			},
			deliveryTotalString() {
				let s = "";
				this.deliveryTotal.forEach((value, key, map) => {
					s += `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value)} ${key}  `;
				});
				return s;
			},
			productTotalString() {
				let s = "";
				this.productTotal.forEach((value, key, map) => {
					s += `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value)} ${key}  `;
				});
				return s;
			},
			productAndDeliveryTotal() {
				let totals = new Map();
				this.productTotal.forEach((value, key, map) => {
					if (!totals.has(key)) {
						totals.set(key, 0);
					}
					let updatedTotal = totals.get(key) + value;
					totals.set(key, updatedTotal);
				});
				this.deliveryTotal.forEach((value, key, map) => {
					if (!totals.has(key)) {
						totals.set(key, 0);
					}
					let updatedTotal = totals.get(key) + value;
					totals.set(key, updatedTotal);
				});
				return totals;
			},
			productAndDeliveryTotalString() {
				let s = "";
				let counter = 0;
				this.productAndDeliveryTotal.forEach((value, key, map) => {
					counter++;
					if (counter < map.size) {
						s += `${new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(value)} ${key} + `;
					} else {
						s += `${new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(value)} ${key}`;
					}
				});
				return s;
			},
			paymentMethods() {
				return Object.keys(this.payment);
			},
			isPaymentInputActive() {
				if (this.paymentInputValue == "") {
					return true;
				}
				return false;
			},
			isDeliveryInputActive() {
				if (this.deliveryInputValue == "") {
					return true;
				}
				return false;
			},
			deliveryFeeDisplay() {
				if (
					this.deliveryFee == null &&
					this.deliveryInputValue != "*" &&
					this.deliveryInputValue != ""
				) {
					return "Calculating...";
				}
				if (this.deliveryFee == null) {
					return "Enter Delivery Details";
				} else {
					return `${new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(this.deliveryFee.fee)} ${this.deliveryFee.currency}`;
				}
			},
		},
		methods: {
			launchModal() {
				this.isQuickPurchaseActive = true;
			},
			cancel() {
				this.isQuickPurchaseActive = false;
			},
			savedPaymentMethods(category) {
				return Object.keys(this.payment[category]);
			},
			details() {
				let products = [];
				if (this.products_prop) {
					products = this.products_prop;
				} else if (this.product_prop) {
					let product = this.product_prop;
					product[0].choices = this.$store.getters.choices;
					products = product;
				}
				return {
					method: "quick-purchase",
					delivery: this.getDelivery(),
					payment: this.getPayment(),
					products: products,
				};
			},
			image(item) {
				let link = "";
				if (item.images[0]) {
					link = window.location.origin + item.images[0];
				}
				return link;
			},
			quantity(c) {
				let choicesArray = this.$store.getters.choices;
				if (this.products_prop) {
					choicesArray = c.choices;
				} else if (this.product_prop) {
					choicesArray = this.$store.getters.choices;
				}
				if (choicesArray == undefined) {
					return 0;
				}
				if (choicesArray.length == 0) {
					return 1;
				} //This case would likely be a single product, thus no quantity for the user to select.
				else {
					for (
						let counter = 0, count = choicesArray.length;
						counter < count;
						counter++
					) {
						if (choicesArray[counter].choice == "quantity") {
							return choicesArray[counter].value;
						}
					}
				}
			},
			choices(c) {
				let choicesArray = [];

				if (this.products_prop) {
					choicesArray = c.choices;
				} else if (this.product_prop) {
					choicesArray = this.$store.getters.choices;
				}

				if (choicesArray == undefined) {
					return "";
				}
				if (choicesArray.length <= 0) {
					return "";
				} //If there are no choices return an empty string
				if (choicesArray.length == 1) {
					return "";
				} //The last element is quantity which is handled by a quantity method
				if (choicesArray.length >= 2) {
					let choices_string = "";
					for (
						let counter = 0, count = choicesArray.length - 2;
						counter < count;
						counter++
					) {
						if (
							!!choicesArray[counter] &&
							choicesArray[counter].value != ""
						) {
							choices_string += choicesArray[counter].value;
						}
						if (
							!!choicesArray[counter + 1] &&
							choicesArray[counter + 1].value != undefined &&
							choicesArray[counter + 1].value != "" &&
							choicesArray[counter + 1].name != "quantity"
						) {
							choices_string += "&nbsp;•&nbsp;";
						}
					}
					// Don't want the last choice to have • at the end
					if (
						choicesArray[choicesArray.length - 2].value !== null &&
						choicesArray[choicesArray.length - 2].value !== undefined
					) {
						choices_string += choicesArray[choicesArray.length - 2].value;
					}
					this.choices_string = choices_string;
					return choices_string;
				}
			},
			price(item, quantity = 1) {
				if (item.pricing) {
					return (
						new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(item.pricing.current * this.quantity(item)) +
						" " +
						item.pricing.currency
					);
				} else {
					return new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(item.price * this.quantity(item));
				}
			},
			purchase() {
				let _this = this;
				this.$refs.purchaseButton.classList.add("is-loading");
				this.$refs.purchaseButton.setAttribute("disabled", "");
				let launchDelay = 1000,
					closeDelay = launchDelay - 500;
				// Visual pause for the user after clicking the purchase button
				_this.$refs.iframeModal.launchModal(launchDelay);

				axios
					.post(
						_this.endpoint, //url
						_this.details(), //payload
						{ headers: { "Content-Type": "application/json" } } //configuration
					)
					.then(function (response) {
						console.log(response);
						// _this.isQuickPurchaseActive = false;
						_this.iframeContent = response.data;
						// The iframe should now contain iframe content in response.data
						// the iFrameModal takes over the rest of the transaction.

						/*
	                    TODO: This is likely not where clearing cart should be done after
	                    implementing the Payment Gateway, make sure to move the code to clear
	                    the cart to a more appropriate location.

	                    A possible bug is the transaction failing and the items in the cart
	                    are cleared out improperly.

	                    Another possible bug is the server not getting the products in the
	                    cart to process because the cart has been cleared.
	                 */

						if (_this.$props.clear_cart) {
							window.app.shoppingCart.clearCart();
						}
					})
					.catch((error) => {
						console.error(error);
						_this.$refs.purchaseButton.classList.remove("is-loading");
						_this.$refs.purchaseButton.removeAttribute("disabled");
						_this.$refs.iframeModal.closeModal(closeDelay);
						_this.$buefy.dialog.alert({
							type: "is-danger",
							title: "Purchase Unsuccessful",
							hasIcon: true,
							message:
								"Your purchase was unsuccessful, please try again later. Ensure that your information has been entered correctly and that you are connected to the internet. If the problem persists please contact us.",
						});
						_this.$refs.paymentInput.clear();
					})
					.then(function () {
						// _this.$refs.purchaseButton.classList.remove("is-loading");
						// _this.$refs.purchaseButton.removeAttribute("disabled");
						_this.$store.dispatch("get_quick_purchase_information");
					});
			},
			getDelivery() {
				let d = new Object();
				d.value = this.$refs.deliveryValue.value;
				if (this.$refs.deliveryValue.value == "") {
					d.details = this.$refs.deliveryInput.details;
					this.save_delivery_information &&
					this.$refs.delivery_name.value != ""
						? (d.save = this.$refs.delivery_name.value)
						: (d.save = null);
				}
				return d;
			},
			getPayment() {
				let p = new Object();
				if (
					this.$refs.paymentValue.options[
						this.$refs.paymentValue.options.selectedIndex
					].dataset.method == "manual"
				) {
					p.method = this.$refs.paymentInput.method;
					p.value = this.$refs.paymentValue.value;
					p.details = this.$refs.paymentInput.details;
				} else {
					p.method =
						this.$refs.paymentValue.options[
							this.$refs.paymentValue.options.selectedIndex
						].dataset.method;
					p.value = this.$refs.paymentValue.value;
					if (this.$refs.paymentValue.value == "") {
						p.details = this.$refs.paymentInput.details;
					}
				}
				this.save_payment_information && this.$refs.payment_name.value != ""
					? (p.save = this.$refs.payment_name.value)
					: (p.save = null);
				return p;
			},
			cancelHandler() {
				if (typeof this.$refs.paymentInput !== "undefined") {
					this.$refs.paymentInput.clear();
				}
			},
			fetchSavedDeliveryMethodFee() {
				if (!this.isLoggedIn || this.deliveryInputValue == "") {
					return;
				}

				let _this = this;
				axios
					.post(
						`${_this.$store.state.origin}/delivery_fee`, //url
						{
							savedDeliveryMethod: _this.deliveryInputValue,
							products: _this.products,
						}, //payload
						{ headers: { "Content-Type": "application/json" } } //configuration
					)
					.then(function (res) {
						console.info("Quick Purchase fetch delivery fee");
						_this.deliveryFee = res.data;
					})
					.catch((error) => {
						_this.deliveryFee = null;
					})
					.then(function (res) {});
			},
		},
		watch: {
			isPaymentInputActive: {
				handler() {
					this.$nextTick(() => {
						if (typeof this.$refs.paymentInput != "undefined") {
							this.paymentInputData = this.$refs.paymentInput.$data;
						}
					});
				},
			},
			paymentInputData: {
				deep: true,
				handler() {
					// let method = this.$refs.paymentInput.paymentMethod;
					if (this.paymentInputData.paymentMethod == "Bank Card") {
						this.showSavePaymentMethod = true;
					} else {
						this.showSavePaymentMethod = false;
					}
				},
			},
			paymentValueElement: {
				deep: true,
				handler() {
					if (this.$refs.paymentValue) {
						if (this.paymentInputValue != "") {
							this.payment = this.paymentInputValue;
						}
					}
					if (this.isPaymentInputActive) {
						let p = this.$refs.paymentInput.details;
						p.save_payment_information = this.save_payment_information;
						this.payment = p;
					}
				},
			},
			state: {
				deep: true,
				handler() {
					if (this.$store.getters.quick_purchase.info) {
						if (this.$store.getters.quick_purchase.info.address) {
							this.locations = Object.keys(
								this.$store.getters.quick_purchase.info.address
							);
						}
						if (this.$store.getters.quick_purchase.info.payment) {
							this.payment =
								this.$store.getters.quick_purchase.info.payment;
						}
						//Manually Enter
						if (this.locations.length == 0) {
							this.deliveryInputValue = "";
						}
						let has_saved_payment_methods = false;
						for (
							let counter = 0;
							counter < this.paymentMethods.length;
							counter++
						) {
							if (
								this.savedPaymentMethods(this.paymentMethods[counter])
									.length
							) {
								has_saved_payment_methods = true;
								break;
							}
						}
						if (!has_saved_payment_methods) {
							this.paymentInputValue = "";
						}
					}
				},
			},
			deliveryInputValue(newValue, oldValue) {
				this.fetchSavedDeliveryMethodFee();
			},
			products(newValue, oldValue) {
				this.fetchSavedDeliveryMethodFee();
			},
		},
	};
</script>
