<template>
	<div class="p-0 m-0">
		<p class="">Select Your Preferred Payment Method:</p>
		<div class="">
			<b-field>
				<b-radio
					v-model="paymentMethod"
					size="is-normal"
					native-value="Bank Card">
					Credit/Debit Card
				</b-radio>
			</b-field>
			<b-field>
				<b-radio
					v-model="paymentMethod"
					size="is-normal"
					native-value="Lynk">
					Lynk
				</b-radio>
			</b-field>
		</div>
		<hr style="border-top: 1px solid #ddd" />
		<div v-show="paymentMethod == 'Lynk'">
			<p>
				Make your payment with Lynk using the QR Code below or send directly
				to the Lynk ID
				<span class="has-text-success has-text-weight-bold">@jamart</span>,
				then enter your transaction id in the box below. Click here to watch
				an instructional video on how to pay with Lynk through our platform.
			</p>
			<p class="has-text-centered is-size-4 has-text-success mb-0">
				Jamart Marketplace Limited
			</p>
			<p
				class="has-text-centered is-size-4 has-text-success"
				style="line-height: 1rem">
				@jamart
			</p>

			<img
				class="mb-2"
				style="max-width: 90%; margin-left: 5%; margin-right: 5%"
				src="/image/payment/lynk_qr_code.png" />
			<!--
            <span class="is-block has-text-centered is-size-5 has-text-gray" >
               Click the image to expand it.
            </span>
         -->
			<p class="has-text-centered is-size-4 has-text-success">
				Total: <span class=""> {{ totalString }}</span>
			</p>
			<input
				ref="lynkTransactionCode"
				class="input"
				type="input"
				placeholder="Lynk Transaction Code e.g. 198765432" />
		</div>
		<div v-show="paymentMethod == 'Bank Card'">
			<div class="field pt-2">
				<div class="columns is-mobile pl-2 pr-2 pt-3">
					<div class="column has-text-left m-0 p-0">
						<label
							class="label"
							for="confirm-email"
							>Card Number</label
						>
					</div>
					<div class="column has-text-right m-0 p-0 is-narrow">
						<i class="fab fa-lg fa-cc-visa"></i>&nbsp;&nbsp;<i
							class="fab fa-lg fa-cc-mastercard"></i>
					</div>
				</div>
				<div class="control">
					<!--Credit Card Length is between 10 and 19 and usually 16 -->
					<input
						v-model="payment.card_number"
						@input="(event) => formatCreditCard(event)"
						class="input"
						type="text"
						maxlength="23"
						pattern="([0-9,-])*"
						placeholder="e.g. 4832-5432-3426-9243" />
				</div>
			</div>
			<div class="field mt-5 is-horizontal">
				<div class="field-body">
					<div class="field">
						<label
							class="label"
							for=""
							>Expiration Date <i style="">(mm/yy)</i></label
						>
						<input
							v-model="payment.expiration"
							@input="(event) => formatExpiryDate(event)"
							class="input"
							type="text"
							maxlength="5"
							:pattern="'^(0?[1-9]?|1[0-2])(\/?)([2-9]{1}[0-9]?){0,1}$'"
							placeholder="e.g. 02/26" />
						<!-- '^(0?[1-9]?|1[0-2])(\/?)([2-9]{1}[0-9]?){0,1}$' -->
						<!-- Not using the below regex because the users browser may not have the correct date -->
						<!-- '^(0?[1-9]?|1[0-2])(\/?)([' +
							new Date().getFullYear().toString().substr(2)[0] +
							'-9]{1}[' +
							new Date().getFullYear().toString().substr(2)[1] +
							'-9]?){0,1}$' -->
					</div>
					<div
						class="field w-100-mobile"
						style="width: 40%">
						<label
							class="label"
							for=""
							>CVC</label
						>
						<input
							v-model="payment.cvc"
							class="input"
							type="text"
							maxlength="4"
							pattern="[0-9]*"
							placeholder="e.g. 123" />
					</div>
				</div>
			</div>
			<div class="field mt-3">
				<div class="columns pl-2 pr-2 pt-3">
					<div class="column has-text-left m-0 p-0">
						<label
							class="label ml-1"
							for="confirm-email"
							>Cardholder Name</label
						>
					</div>
				</div>
				<div class="control">
					<input
						v-model="payment.name"
						class="input"
						type="text"
						maxlength="61"
						pattern="^([-A-z]{0,30}[ ]?[-A-z]{0,30})$"
						placeholder="e.g. Johnathan Doberman" />
				</div>
			</div>
			<div>
				<div class="pt-2">
					<b-checkbox v-model="showBillingAddress"
						>Enter Billing Address (Recommended)</b-checkbox
					>
					<div class="field">
						<p class="help has-text-grey is-size-7">
							Your billing address is not absolutely required but it
							helps our payment processor verify your information and
							makes your transaction more seamless.
						</p>
					</div>
					<BillingAddressInput
						ref="billingAddressInput"
						v-show="showBillingAddress" />
				</div>
			</div>
			<div class="columns is-mobile">
				<div
					class="column is-5"
					style="margin-right: auto">
					<figure
						class="image ml-0 mr-0 mt-0 mb-0"
						style="max-height: 5.5rem; aspect-ratio: 772 / 346">
						<img src="/image/asset/powered-by-fac.jpg" />
					</figure>
				</div>
				<div class="column is-7">
					<div class="columns is-multiline is-mobile">
						<div
							class="column is-narrow w-50-mobile pr-1 pb-0"
							style="margin-left: auto">
							<figure
								class="image ml-0 mr-1 mt-0 mb-0"
								style="max-height: 3rem; aspect-ratio: 1 / 1">
								<img
									src="/image/asset/payment-visa-secure.png"
									style="border: 1px solid rgb(181, 181, 181)" />
							</figure>
						</div>
						<div class="column is-narrow w-50-mobile pl-1 pb-0">
							<figure
								class="image m-0 p-0"
								style="max-height: 3rem; aspect-ratio: 327 / 245">
								<img
									src="/image/asset/payment-mastercard-id-check.png"
									style="border: 1px solid rgb(181, 181, 181)" />
							</figure>
						</div>
						<div class="column is-full pt-0">
							<p
								class="is-size-7 pt-0 pb-0 m-0 has-text-right pr-0 has-text-weight-bold">
								3D-Secure
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css" scoped>
	/* Mobile: up to 768px */
	@media screen and (max-width: 768px) {
		.w-50-mobile {
			width: 50% !important;
		}
		.w-100-mobile {
			width: 100% !important;
		}
	}
	.label {
		font-weight: normal;
		font-size: 0.9rem;
	}
	input:valid {
	}
	input:invalid {
		background-color: rgb(255, 190, 190);
	}
	figure img,
	img {
		max-height: initial; /*max-height set to initial to counteract navbar-item img max-height*/
	}
</style>
<script type="text/javascript">
	import BillingAddressInput from "./BillingAddressInput.vue";
	export default {
		components: { BillingAddressInput },
		props: {
			total: Map,
		},
		data() {
			return {
				paymentMethod: "Bank Card",
				payment: {
					card_number: "",
					expiration: "",
					cvc: "",
					name: "",
				},
				showBillingAddress: false,
			};
		},
		computed: {
			details() {
				let payment = null;
				switch (this.paymentMethod) {
					case "Bank Card":
						payment = this.payment;

						payment.card_number = payment.card_number.replace(/\D/g, "");
						payment.expiration = payment.expiration.replace(/\D/g, "");
						payment.cvc = payment.cvc.replace(/\D/g, "");
						if (this.showBillingAddress == true) {
							payment.billingAddress =
								this.$refs.billingAddressInput.details();
						} else {
							// for some reason if billing address is entered once in the parent object it will be there
							// this line will be used to delete billingAddress if showBillingAddress is false
							if (typeof payment.billingAddress != "undefined") {
								delete payment.billingAddress;
							}
						}
						break;
					case "Lynk":
						payment = {};
						payment.transactionCode =
							this.$refs.lynkTransactionCode.value;
						break;
				}
				return payment;
			},
			method() {
				return this.paymentMethod;
			},
			totalString() {
				let s = "";
				let counter = 0;
				this.total.forEach((value, key, map) => {
					counter++;
					if (counter < map.size) {
						s += `${new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(value)} ${key} + `;
					} else {
						s += `${new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(value)} ${key}`;
					}
				});
				return s;
			},
		},
		methods: {
			clear() {
				Object.keys(this.payment).forEach(
					(paymentAttribute, index, array) => {
						this.payment[paymentAttribute] = "";
					}
				);
				this.$refs.showBillingAddress = false;
			},
			formatCreditCard(event) {
				//store regex mm/yy format in a string
				//store the regex for a number 01 to 12 in a string variable

				let x = this.payment.card_number;
				let index = x.lastIndexOf("-");
				let test = x.substr(index + 1);
				if (
					event.inputType === "deleteContentBackward" &&
					test.length === 0
				) {
					this.payment.card_number = x.substr(0, x.length - 1);
				} else if (
					event.inputType === "insertText" &&
					(test.length === 0 || test.length > 4)
				) {
					setTimeout(() => {
						this.payment.card_number =
							x.substr(0, x.length - 1) + "-" + x[x.length - 1];
					}, 1);
				} else if (
					event.inputType === "deleteContentBackward" &&
					test.length === 4
				) {
					setTimeout(() => {
						this.payment.card_number = this.payment.card_number =
							x.substr(0, x.length - 1);
					}, 1);
				} else if (
					event.inputType === "deleteContentBackward" &&
					index === -1 &&
					this.payment.card_number.length > 4
				) {
					setTimeout(() => {
						this.payment.card_number = x.substr(0, x.length - 1);
					}, 1);
				} else if (
					test.length === 4 &&
					this.payment.card_number.replace(/-/g, "").length < 19
				) {
					// this if is to ensure that if 16 digits is entered to not automatically add the dash,
					// the card number is usually 16 but for some cards it can be 19. so accomodate for that
					// but presume that 16 digits will be entered and don't automatically add the dash after 16 characters
					if (this.payment.card_number.replace(/-/g, "").length != 16) {
						this.payment.card_number = x + "-";
					}
				}
			},
			formatExpiryDate(event) {
				// Set payment.expiration to mm/yy and format it
				let x = this.payment.expiration;
				let index = x.lastIndexOf("/");
				let test = x.substr(index + 1);
				console.debug("Index: " + index + " Test: " + test);
				if (
					event.inputType === "deleteContentBackward" &&
					test.length === 0
				) {
					this.payment.expiration = x.substr(0, x.length - 1);
				} else if (
					event.inputType === "deleteContentBackward" &&
					test.length == 2 &&
					test.length
				) {
					setTimeout(() => {
						this.payment.expiration = x.substr(0, x.length - 1);
					}, 1);
				} else if (event.inputType === "insertText" && test.length > 2) {
					// automatically add the slash if more than two characters are entered
					setTimeout(() => {
						this.payment.expiration =
							x.substr(0, x.length - 1) + "/" + x.substr(x.length - 1);
					}, 1);
				} else if (
					event.inputType === "insertText" &&
					test.length == 2 &&
					this.payment.expiration.length < 4
				) {
					// automatically add the slash if only two characters are entered
					setTimeout(() => {
						this.payment.expiration = x + "/";
					}, 1);
				} else if (index == -1 && this.payment.expiration.length > 2) {
					//automatically add the slash if only three characters are entered
					setTimeout(() => {
						this.payment.expiration =
							x.substr(0, 2) + "/" + x.substr(2, x.length);
					}, 1);
				}

				if (
					index == 1 &&
					this.payment.expiration.length > 2 &&
					parseInt(this.payment.expiration.substr(0, 2)) < 10
				) {
					setTimeout(() => {
						this.payment.expiration = "0" + this.payment.expiration;
					}, 2);
				}
			},
		},
	};
</script>
