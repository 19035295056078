<template>
	<!-- Parent Component needs classes ".navbar-item .has-dropdown .is-hoverable -->
	<div
		ref="dropdown"
		class="navbar-item has-dropdown">
		<button
			@click="toggleDropdown"
			class="button is-warning is-size-7 has-text-weight-bold p-4">
			<i class="fas fa-lg fa-bars ml-2 mr-2"></i
			><span>SHOP BY CATEGORY</span>
		</button>
		<div
			@mouseleave="toggleDropdown"
			class="navbar-dropdown p-0">
			<div class="dropdown-content">
				<div class="dropdown-item p-1">
					<div style="min-width: 100%">
						<div
							class="columns"
							ref="categoriesDropdown">
							<div
								class="column is-4"
								ref="categories"
								style="overflow-y: scroll; overflow-x: hidden">
								<div class="columns is-multiline">
									<div
										v-for="(category, index) in computedCategories"
										@mouseover="setSelectedCategory(index)"
										class="column categories-dropdown-main-item is-full mt-0 mr-0 ml-2 mb-0 pl-4 pt-2 pr-2 pb-2">
										<p>{{ index }}</p>
									</div>
								</div>
							</div>
							<div class="column has-background-white-ter">
								<div class="columns is-multiline">
									<div
										v-for="(
											subCategory, subIndex
										) in selectedCategory"
										class="column is-half">
										<div class="block">
											<p class="is-size-5 has-text-weight-bold">
												{{ subIndex }}
											</p>
											<a
												v-for="(item, itemIndex) in subCategory"
												class="categories-dropdown-item"
												href="#"
												><p class="has-text-link">{{ item }}</p></a
											>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="column">(Hygine) Regular doctor and dentist (Children) Kid’s bicycles (Clothing) Running shoes (Entertainment)  equipment, such as an Xbox or PlayStation (Household) Kitchen utensils – plates, pots and pans</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.categories-dropdown-main-item {
		transition: background-color 0.1s, color 0.1s;
	}
	.categories-dropdown-main-item:hover {
		background-color: hsl(217, 71%, 45%);
		color: #fff;
	}

	.categories-dropdown-item:hover {
		text-decoration: underline;
	}
</style>
<script>
	export default {
		props: {
			prop_categories: Object,
		},
		data() {
			return {
				category: null,
				categories: {
					Computers: {
						"Computer Parts": [
							"Monitors",
							"Mouse",
							"Mouse Pad",
							"Keyboard",
							"Fan",
							"Chargers",
						],
						"Computer Case": ["Plastic", "Enclosure"],
						Brands: [
							"Intel",
							"AMD",
							"Dell",
							"Asus",
							"Hewlett Packard (HP)",
						],
					},
					"Business & Industrial": {
						Healthcare: ["Dental Supplies", "Medical Lab Supplies"],
						Safety: [
							"Personal Protective Equipment (PPE)",
							"Fire Protection Equipment",
							"Surveillance & Alarm Equipment",
							"Public Sarety Equipment",
						],
					},
					School: {
						Textbooks: ["Past Papers", "Pre-Owned"],
					},
					"Mobile phones": {
						Accessories: ["Case", "Charger", "Pop Holder", "Stickers"],
						Brands: ["Samsung", "Motorola"],
					},
					Entertainment: {
						Books: [],
						Movies: [],
						Music: [
							"Pianos and Keyboards",
							"Guitars and Bases",
							"Audio Equipment",
							"Wind and Woodwind Instruments",
						],
					},
					Clothing: {
						"Women's Clothing": [
							"Dresses",
							"Tops",
							"Sleepwear",
							"Jacket, Vests, Sweaters",
							"Footwear",
							"Women's Hats",
							"Women's Belts",
							"",
						],
						"Men's Clothing": [],
						"Costume, Occasion & Specialized Clothing": [
							"Uniforms and Work Clothing",
							"Wedding and Formal Wear",
							"Costumes",
						],
					},
					Sports: {
						"Outdoor Sports": [
							"Golf Clubs and Equipment",
							"Skateboarding",
							"Cycling Equipment",
						],
						"Team Sports": ["Basket Ball", "Football", "Bowling"],
						"Indoor Games": [
							"Ballards",
							"Darts",
							"Table Tennis",
							"Board Games",
							"Pool",
							"Game Machines",
						],
					},
					Restaurants: {
						asd: ["asd"],
					},
					Pets: {
						sdf: ["asdf"],
					},
					Exercise: {
						sdf: ["ds"],
					},
					Vehicle: {
						"Auto Parts": ["Car & Truck Parts", "Motorcycle Parts"],
						Technology: ["GPS & Security Devices", "In-Car Technology"],
					},
					"Cosmetics & Perfumes": {
						asd: ["assdfd"],
					},
					Jewelry: {
						asd: ["asd"],
					},
					Children: {
						sa: ["asd"],
					},
					Furniture: {
						"Baby & Care-giving": ["Crib"],
					},
					Household: {
						Repairs: ["Tools"],
						"Art and Collectibles": ["asd", "asdf"],
						Paint: ["aa", "bb"],
					},
					"Health & Beauty": {
						sd: ["ad", "adsf", "asd"],
					},
				},
			};
		},
		mounted() {
			this.$refs.categoriesDropdown.style.maxWidth = `${Math.floor(
				window.innerWidth * 0.7
			)}px`;
			this.$refs.categoriesDropdown.style.minWidth = `${Math.floor(
				window.innerWidth * 0.6
			)}px`;

			this.$refs.categoriesDropdown.style.maxHeight = `${Math.floor(
				window.innerHeight * 0.7
			)}px`;
			this.$refs.categories.style.maxHeight = `${Math.floor(
				window.innerHeight * 0.7
			)}px`;
		},
		computed: {
			computedCategories() {
				let cg = {};
				this.$props.prop_categories
					? (cg = JSON.parse(this.$props.prop_categories))
					: (cg = this.$data.categories);
				return cg;
			},
			selectedCategory() {
				return this.computedCategories[this.getCategory];
			},
			getCategory() {
				if (this.category) {
					return this.category;
				}
				return Object.keys(this.computedCategories)[0];
			},
		},
		methods: {
			setSelectedCategory(value) {
				this.category = value;
			},
			toggleDropdown() {
				this.$refs.dropdown.classList.contains("is-active")
					? this.$refs.dropdown.classList.remove("is-active")
					: this.$refs.dropdown.classList.add("is-active");
			},
		},
	};
</script>
