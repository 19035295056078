import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import CartCost from "./components/ShoppingCart/CartCost.vue";
import NavigationSearchBar from "./components/NavigationCategories/NavigationSearchBar.vue";
import NavigationSearchBarMobile from "./components/NavigationCategories/NavigationSearchBarMobile.vue";
import NavigationCategories from "./components/NavigationCategories/NavigationCategories.vue";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart.vue";
import UserNavigationIcon from "./components/AuthComponents/UserNavigationIcon.vue";
import WishlistIcon from "./components/WishlistComponents/WishlistIcon.vue";
import GeneralNotification from "./components/Notification/GeneralNotification.vue";

new Vue({
	el: "#header",
	store: window.app.store,
	data() {
		return {};
	},
	components: {
		UserNavigationIcon,
		NavigationSearchBar,
		NavigationSearchBarMobile,
		WishlistIcon,
      ShoppingCart,
      GeneralNotification
	},
	mounted() {},
	methods: {},
});

// new Vue({
//     el:"#navigation",
//     store:window.app.store,
//     data(){return{}},
//     components:{NavigationCategories},
//     mounted(){
//     },
//     methods:{
//     }
// });
