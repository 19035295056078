<template>
	<b-notification
		v-if="isActive"
		:type="notificationType"
		has-icon
		aria-close-label="Close notification"
		position="is-top">
		{{ notificationContent }}
	</b-notification>
</template>
<style scoped></style>
<script type="text/javascript">
	import Vue from "vue";
	import Buefy from "buefy";

	Vue.use(Buefy);
	export default {
		props: {
			content: {
				type: String,
				required: true,
			},
			type: {
				type: String,
			},
			active: {
				type: Boolean,
				default: false,
			},
			programatic: {
				type: Boolean,
				default: false,
			},
		},
		data: () => ({}),
		mounted() {
			if (this.isProgramatic) {
				this.$buefy.notification.open({
					message: this.notificationContent,
					type: this.notificationType,
					position: "is-top",
					indefinite: true,
				});
			}
		},
		methods: {},
		computed: {
			isActive() {
				return this.$props.active;
			},
			isProgramatic() {
				return this.$props.programatic;
			},
			notificationType() {
				if (this.$props.type) {
					return this.$props.type;
				}
				return "is-white";
			},
			notificationContent() {
				if (this.$props.content) {
					return this.$props.content;
				}
				return "";
			},
		},
	};
</script>
