<template>
	<div class="navbar-item px-0 pr-2">
		<div
			v-if="!isLoggedIn"
			class="buttons m-0 p-0">
			<a
				:href="signUpLink"
				class="button is-primary mb-0">
				<strong>Sign up</strong>
			</a>
			<a
				:href="loginLink"
				class="button is-light mb-0">
				Log in
			</a>
		</div>
		<div
			v-else
			data-tippy-id="userButton"
			class="is-clickable columns m-0 is-vcentered"
			style="margin-right: 1.5rem">
			<div
				class="column is-narrow m-0 p-0 is-hidden-mobile has-text-weight-bold ml-0 mr-1"
				style="font-size: 0.9rem">
				<span>&nbsp;My Account&nbsp;</span>
			</div>
			<div class="column is-narrow m-0 p-0 pt-1">
				<i
					class="fa-solid fa-user is-size-5"
					style="cursor: pointer">
				</i>
			</div>
		</div>

		<div
			ref="tippy_content"
			class="is-hidden p-0">
			<div class="has-text-weight-normal">
				<nav
					class="panel is-primary"
					style="">
					<p class="panel-heading has-text-centered">
						<i
							class="fas fa-at"
							style=""></i
						>{{ username }}
					</p>
					<span class="panel-block is-hidden">
						<a class=""><i class="fas fa-user"></i>&nbsp;&nbsp;Profile</a>
					</span>
					<span class="panel-block">
						<a
							class=""
							:href="dashboardLink"
							><i class="fas fa-tachometer-alt"></i
							>&nbsp;&nbsp;Dashboard</a
						>
					</span>
					<span class="panel-block">
						<a
							class=""
							:href="storesLink"
							><i class="fas fa-store-alt"></i>&nbsp;&nbsp;View Your
							Stores</a
						>
					</span>
					<span class="panel-block is-hidden">
						<a class=""
							><i class="fas fa-cog"></i>&nbsp;&nbsp;Settings and Privacy
						</a>
					</span>
					<span class="panel-block is-hidden">
						<a class=""
							><i class="fas fa-flag-checkered"></i>&nbsp;&nbsp;Give
							Feedback or Report Issue</a
						>
					</span>
					<span class="panel-block">
						<a
							class="is-block"
							:href="logoutLink">
							<button
								class="button is-danger is-fullwidth is-size-5"
								style="min-width: 200px">
								<span
									>Logout&nbsp;&nbsp;<i class="fas fa-sign-out-alt"></i
								></span>
							</button>
						</a>
					</span>
				</nav>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	@import "./../../../../sass/app.scss";

	.is-clickable {
		cursor: pointer;
	}

	.is-hidden {
		display: none;
	}
</style>
<script type="text/javascript">
	import tippy from "tippy.js";
	import "tippy.js/dist/tippy.css";
	import "tippy.js/themes/light.css";
	import "tippy.js/animations/scale.css";
	import "tippy.js/animations/scale-subtle.css";

	export default {
		props: {
			prop_signup_link: String,
			prop_login_link: String,
			prop_logout_link: String,
			prop_stores_link: String,
			prop_username: String,
			prop_dashboard_link: String,
		},
		data() {
			return {
				tippy: null,
			};
		},
		mounted() {
			let _this = this;
			this.tippy = tippy("[data-tippy-id='userButton']", {
				theme: "light",
				allowHTML: true,
				placement: "bottom",
				content: this.$refs.tippy_content.innerHTML,
				animation: "scale-subtle",
				trigger: "click",
				interactive: true,
				maxWidth: _this.tippyMaxWidth,
				onShow(instance) {
					// Add p-0 to tippy-content element
					instance.popper.children[0].children[0].classList.add("p-0");
					this.windowHeight = window.innerHeight;
				},
			})[0]; //[0] is needed since an array is returned
		},
		computed: {
			tippyMaxWidth() {
				if (this.isLoggedIn) {
					return "none";
				} else {
					return 600;
				}
			},
			username() {
				return this.$props.prop_username;
			},
			signUpLink() {
				return this.$props.prop_signup_link;
			},
			loginLink() {
				return this.$props.prop_login_link;
			},
			logoutLink() {
				return this.$props.prop_logout_link;
			},
			storesLink() {
				return this.$props.prop_stores_link;
			},
			dashboardLink() {
				return this.$props.prop_dashboard_link;
			},
			isLoggedIn() {
				if (JSON.parse(window.sessionStorage.getItem("logged_in")).value) {
					return true;
				}
				return false;
			},
		},
		methods: {},
	};
</script>
