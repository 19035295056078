<template>
	<div>
		<div class="field has-addons mb-1">
			<p class="control mb-0">
				<span class="select">
					<select
						v-model="selectedCountry"
						@click="dropdownIsFocused = true"
						@focus="dropdownIsFocused = true"
						@blur="dropdownIsFocused = false"
						@input="dropdownIsFocused = false"
						@change="checkPhoneNumberLength"
						ref="dropdown"
						style="width: 7rem; max-width: 7rem">
						<option
							v-for="(
								country, index, array
							) in phoneInformationByCountry"
							v-html="
								dropdownIsFocused
									? `${country.flag} [${country.countryCode}] ${country.country}`
									: `${country.flag} ${country.countryCode}`
							"
							:key="index"
							:selected="selectedCountry == index ? true : null"
							:value="index"></option>
					</select>
				</span>
			</p>
			<p class="control mb-0 is-expanded">
				<input
					ref="phoneNumberInput"
					class="input"
					type="text"
					:pattern="phoneNumberPattern"
					@keyup="
						phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
						checkPhoneNumberLength();
					"
					placeholder="e.g 8761234567 (numbers only)"
					v-model="phoneNumber"
					@input="checkPhoneNumberLength()" />
			</p>
		</div>
		<span
			ref="warningTextArea"
			:class="{
				'has-text-success': isPhoneNumberValid,
				'has-text-warning-dark': !isPhoneNumberValid,
			}"></span>
	</div>
</template>
<style type="text/css" scoped>
	/*
   input:invalid {
		background-color: rgb(255, 190, 190);
	}
   */
</style>
<script type="text/javascript">
	export default {
		props: { modelValue: String },
		emits: ["update:modelValue"],
		data() {
			return {
				selectedCountry: "JM",
				dropdownIsFocused: false,
				phoneNumber: "",
				phoneInformationByCountry: {
					AF: {
						flag: "🇦🇫",
						countryCode: "+93",
						country: "Afghanistan",
						expectedPhoneNumberLength: [9],
					},
					AL: {
						flag: "🇦🇱",
						countryCode: "+355",
						country: "Albania",
						expectedPhoneNumberLength: [8, 9],
					},
					DZ: {
						flag: "🇩🇿",
						countryCode: "+213",
						country: "Algeria",
						expectedPhoneNumberLength: [9, 10],
					},
					AD: {
						flag: "🇦🇩",
						countryCode: "+376",
						country: "Andorra",
						expectedPhoneNumberLength: [6, 7],
					},
					AO: {
						flag: "🇦🇴",
						countryCode: "+244",
						country: "Angola",
						expectedPhoneNumberLength: [9],
					},
					AG: {
						flag: "🇦🇬",
						countryCode: "+1",
						country: "Antigua and Barbuda",
						expectedPhoneNumberLength: [10],
					},
					AR: {
						flag: "🇦🇷",
						countryCode: "+54",
						country: "Argentina",
						expectedPhoneNumberLength: [10, 11],
					},
					AM: {
						flag: "🇦🇲",
						countryCode: "+374",
						country: "Armenia",
						expectedPhoneNumberLength: [8, 9],
					},
					AU: {
						flag: "🇦🇺",
						countryCode: "+61",
						country: "Australia",
						expectedPhoneNumberLength: [9, 10],
					},
					AT: {
						flag: "🇦🇹",
						countryCode: "+43",
						country: "Austria",
						expectedPhoneNumberLength: [10, 11],
					},
					AZ: {
						flag: "🇦🇿",
						countryCode: "+994",
						country: "Azerbaijan",
						expectedPhoneNumberLength: [9],
					},
					BS: {
						flag: "🇧🇸",
						countryCode: "+1",
						country: "Bahamas",
						expectedPhoneNumberLength: [10],
					},
					BH: {
						flag: "🇧🇭",
						countryCode: "+973",
						country: "Bahrain",
						expectedPhoneNumberLength: [8, 9],
					},
					BD: {
						flag: "🇧🇩",
						countryCode: "+880",
						country: "Bangladesh",
						expectedPhoneNumberLength: [9, 10],
					},
					BB: {
						flag: "🇧🇧",
						countryCode: "+1",
						country: "Barbados",
						expectedPhoneNumberLength: [10],
					},
					BY: {
						flag: "🇧🇾",
						countryCode: "+375",
						country: "Belarus",
						expectedPhoneNumberLength: [9],
					},
					BE: {
						flag: "🇧🇪",
						countryCode: "+32",
						country: "Belgium",
						expectedPhoneNumberLength: [9, 10],
					},
					BZ: {
						flag: "🇧🇿",
						countryCode: "+501",
						country: "Belize",
						expectedPhoneNumberLength: [7],
					},
					BJ: {
						flag: "🇧🇯",
						countryCode: "+229",
						country: "Benin",
						expectedPhoneNumberLength: [8, 9],
					},
					BT: {
						flag: "🇧🇹",
						countryCode: "+975",
						country: "Bhutan",
						expectedPhoneNumberLength: [8, 9],
					},
					BO: {
						flag: "🇧🇴",
						countryCode: "+591",
						country: "Bolivia",
						expectedPhoneNumberLength: [8, 9],
					},
					BA: {
						flag: "🇧🇦",
						countryCode: "+387",
						country: "Bosnia and Herzegovina",
						expectedPhoneNumberLength: [8, 9],
					},
					BW: {
						flag: "🇧🇼",
						countryCode: "+267",
						country: "Botswana",
						expectedPhoneNumberLength: [8, 9],
					},
					BR: {
						flag: "🇧🇷",
						countryCode: "+55",
						country: "Brazil",
						expectedPhoneNumberLength: [10, 11],
					},
					BN: {
						flag: "🇧🇳",
						countryCode: "+673",
						country: "Brunei",
						expectedPhoneNumberLength: [7, 8],
					},
					BG: {
						flag: "🇧🇬",
						countryCode: "+359",
						country: "Bulgaria",
						expectedPhoneNumberLength: [8, 9],
					},
					BF: {
						flag: "🇧🇫",
						countryCode: "+226",
						country: "Burkina Faso",
						expectedPhoneNumberLength: [8],
					},
					BI: {
						flag: "🇧🇮",
						countryCode: "+257",
						country: "Burundi",
						expectedPhoneNumberLength: [8],
					},
					KH: {
						flag: "🇰🇭",
						countryCode: "+855",
						country: "Cambodia",
						expectedPhoneNumberLength: [8, 9],
					},
					CM: {
						flag: "🇨🇲",
						countryCode: "+237",
						country: "Cameroon",
						expectedPhoneNumberLength: [8, 9],
					},
					CA: {
						flag: "🇨🇦",
						countryCode: "+1",
						country: "Canada",
						expectedPhoneNumberLength: [10],
					},
					CV: {
						flag: "🇨🇻",
						countryCode: "+238",
						country: "Cape Verde",
						expectedPhoneNumberLength: [7, 8],
					},
					CF: {
						flag: "🇨🇫",
						countryCode: "+236",
						country: "Central African Republic",
						expectedPhoneNumberLength: [8],
					},
					TD: {
						flag: "🇹🇩",
						countryCode: "+235",
						country: "Chad",
						expectedPhoneNumberLength: [8],
					},
					CL: {
						flag: "🇨🇱",
						countryCode: "+56",
						country: "Chile",
						expectedPhoneNumberLength: [9],
					},
					CN: {
						flag: "🇨🇳",
						countryCode: "+86",
						country: "China",
						expectedPhoneNumberLength: [11],
					},
					CO: {
						flag: "🇨🇴",
						countryCode: "+57",
						country: "Colombia",
						expectedPhoneNumberLength: [10],
					},
					KM: {
						flag: "🇰🇲",
						countryCode: "+269",
						country: "Comoros",
						expectedPhoneNumberLength: [7],
					},
					CG: {
						flag: "🇨🇬",
						countryCode: "+242",
						country: "Congo (Republic of the)",
						expectedPhoneNumberLength: [9],
					},
					CD: {
						flag: "🇨🇩",
						countryCode: "+243",
						country: "Congo (Democratic Republic of the)",
						expectedPhoneNumberLength: [9],
					},
					CR: {
						flag: "🇨🇷",
						countryCode: "+506",
						country: "Costa Rica",
						expectedPhoneNumberLength: [8, 9],
					},
					HR: {
						flag: "🇭🇷",
						countryCode: "+385",
						country: "Croatia",
						expectedPhoneNumberLength: [8, 9],
					},
					CU: {
						flag: "🇨🇺",
						countryCode: "+53",
						country: "Cuba",
						expectedPhoneNumberLength: [8, 9],
					},
					CY: {
						flag: "🇨🇾",
						countryCode: "+357",
						country: "Cyprus",
						expectedPhoneNumberLength: [8, 9],
					},
					CZ: {
						flag: "🇨🇿",
						countryCode: "+420",
						country: "Czech Republic",
						expectedPhoneNumberLength: [9],
					},
					DK: {
						flag: "🇩🇰",
						countryCode: "+45",
						country: "Denmark",
						expectedPhoneNumberLength: [8],
					},
					DJ: {
						flag: "🇩🇯",
						countryCode: "+253",
						country: "Djibouti",
						expectedPhoneNumberLength: [7, 8],
					},
					DM: {
						flag: "🇩🇲",
						countryCode: "+1",
						country: "Dominica",
						expectedPhoneNumberLength: [10],
					},
					DO: {
						flag: "🇩🇴",
						countryCode: "+1",
						country: "Dominican Republic",
						expectedPhoneNumberLength: [10],
					},
					EC: {
						flag: "🇪🇨",
						countryCode: "+593",
						country: "Ecuador",
						expectedPhoneNumberLength: [9],
					},
					EG: {
						flag: "🇪🇬",
						countryCode: "+20",
						country: "Egypt",
						expectedPhoneNumberLength: [10, 11],
					},
					SV: {
						flag: "🇸🇻",
						countryCode: "+503",
						country: "El Salvador",
						expectedPhoneNumberLength: [8, 9],
					},
					GQ: {
						flag: "🇬🇶",
						countryCode: "+240",
						country: "Equatorial Guinea",
						expectedPhoneNumberLength: [7],
					},
					ER: {
						flag: "🇪🇷",
						countryCode: "+291",
						country: "Eritrea",
						expectedPhoneNumberLength: [7],
					},
					EE: {
						flag: "🇪🇪",
						countryCode: "+372",
						country: "Estonia",
						expectedPhoneNumberLength: [7, 8],
					},
					ET: {
						flag: "🇪🇹",
						countryCode: "+251",
						country: "Ethiopia",
						expectedPhoneNumberLength: [9, 10],
					},
					FJ: {
						flag: "🇫🇯",
						countryCode: "+679",
						country: "Fiji",
						expectedPhoneNumberLength: [7, 8],
					},
					FI: {
						flag: "🇫🇮",
						countryCode: "+358",
						country: "Finland",
						expectedPhoneNumberLength: [9],
					},
					FR: {
						flag: "🇫🇷",
						countryCode: "+33",
						country: "France",
						expectedPhoneNumberLength: [9],
					},
					GA: {
						flag: "🇬🇦",
						countryCode: "+241",
						country: "Gabon",
						expectedPhoneNumberLength: [8],
					},
					GM: {
						flag: "🇬🇲",
						countryCode: "+220",
						country: "Gambia",
						expectedPhoneNumberLength: [7],
					},
					GE: {
						flag: "🇬🇪",
						countryCode: "+995",
						country: "Georgia",
						expectedPhoneNumberLength: [9],
					},
					DE: {
						flag: "🇩🇪",
						countryCode: "+49",
						country: "Germany",
						expectedPhoneNumberLength: [10, 11],
					},
					GH: {
						flag: "🇬🇭",
						countryCode: "+233",
						country: "Ghana",
						expectedPhoneNumberLength: [9],
					},
					GR: {
						flag: "🇬🇷",
						countryCode: "+30",
						country: "Greece",
						expectedPhoneNumberLength: [10],
					},
					GD: {
						flag: "🇬🇩",
						countryCode: "+1",
						country: "Grenada",
						expectedPhoneNumberLength: [10],
					},
					GT: {
						flag: "🇬🇹",
						countryCode: "+502",
						country: "Guatemala",
						expectedPhoneNumberLength: [8, 9],
					},
					GN: {
						flag: "🇬🇳",
						countryCode: "+224",
						country: "Guinea",
						expectedPhoneNumberLength: [8],
					},
					GW: {
						flag: "🇬🇼",
						countryCode: "+245",
						country: "Guinea-Bissau",
						expectedPhoneNumberLength: [7],
					},
					GY: {
						flag: "🇬🇾",
						countryCode: "+592",
						country: "Guyana",
						expectedPhoneNumberLength: [7, 8],
					},
					HT: {
						flag: "🇭🇹",
						countryCode: "+509",
						country: "Haiti",
						expectedPhoneNumberLength: [8],
					},
					HN: {
						flag: "🇭🇳",
						countryCode: "+504",
						country: "Honduras",
						expectedPhoneNumberLength: [8, 9],
					},
					HU: {
						flag: "🇭🇺",
						countryCode: "+36",
						country: "Hungary",
						expectedPhoneNumberLength: [9],
					},
					IS: {
						flag: "🇮🇸",
						countryCode: "+354",
						country: "Iceland",
						expectedPhoneNumberLength: [7, 8],
					},
					IN: {
						flag: "🇮🇳",
						countryCode: "+91",
						country: "India",
						expectedPhoneNumberLength: [10, 11],
					},
					ID: {
						flag: "🇮🇩",
						countryCode: "+62",
						country: "Indonesia",
						expectedPhoneNumberLength: [9, 10],
					},
					IR: {
						flag: "🇮🇷",
						countryCode: "+98",
						country: "Iran",
						expectedPhoneNumberLength: [10],
					},
					IQ: {
						flag: "🇮🇶",
						countryCode: "+964",
						country: "Iraq",
						expectedPhoneNumberLength: [10],
					},
					IE: {
						flag: "🇮🇪",
						countryCode: "+353",
						country: "Ireland",
						expectedPhoneNumberLength: [9],
					},
					IL: {
						flag: "🇮🇱",
						countryCode: "+972",
						country: "Israel",
						expectedPhoneNumberLength: [9],
					},
					IT: {
						flag: "🇮🇹",
						countryCode: "+39",
						country: "Italy",
						expectedPhoneNumberLength: [10],
					},
					JM: {
						flag: "🇯🇲",
						countryCode: "+1",
						country: "Jamaica",
						expectedPhoneNumberLength: [10],
					},
					JP: {
						flag: "🇯🇵",
						countryCode: "+81",
						country: "Japan",
						expectedPhoneNumberLength: [10],
					},
					JO: {
						flag: "🇯🇴",
						countryCode: "+962",
						country: "Jordan",
						expectedPhoneNumberLength: [9],
					},
					KZ: {
						flag: "🇰🇿",
						countryCode: "+7",
						country: "Kazakhstan",
						expectedPhoneNumberLength: [10],
					},
					KE: {
						flag: "🇰🇪",
						countryCode: "+254",
						country: "Kenya",
						expectedPhoneNumberLength: [9],
					},
					KI: {
						flag: "🇰🇮",
						countryCode: "+686",
						country: "Kiribati",
						expectedPhoneNumberLength: [5],
					},
					KW: {
						flag: "🇰🇼",
						countryCode: "+965",
						country: "Kuwait",
						expectedPhoneNumberLength: [8],
					},
					KG: {
						flag: "🇰🇬",
						countryCode: "+996",
						country: "Kyrgyzstan",
						expectedPhoneNumberLength: [9],
					},
					LA: {
						flag: "🇱🇦",
						countryCode: "+856",
						country: "Laos",
						expectedPhoneNumberLength: [8, 9],
					},
					LV: {
						flag: "🇱🇻",
						countryCode: "+371",
						country: "Latvia",
						expectedPhoneNumberLength: [8],
					},

					LB: {
						flag: "🇱🇧",
						countryCode: "+961",
						country: "Lebanon",
						expectedPhoneNumberLength: [7, 8],
					},
					LS: {
						flag: "🇱🇸",
						countryCode: "+266",
						country: "Lesotho",
						expectedPhoneNumberLength: [8, 9],
					},
					LR: {
						flag: "🇱🇷",
						countryCode: "+231",
						country: "Liberia",
						expectedPhoneNumberLength: [7, 8],
					},
					LY: {
						flag: "🇱🇾",
						countryCode: "+218",
						country: "Libya",
						expectedPhoneNumberLength: [9],
					},
					LI: {
						flag: "🇱🇮",
						countryCode: "+423",
						country: "Liechtenstein",
						expectedPhoneNumberLength: [7, 8],
					},
					LT: {
						flag: "🇱🇹",
						countryCode: "+370",
						country: "Lithuania",
						expectedPhoneNumberLength: [8, 9],
					},
					LU: {
						flag: "🇱🇺",
						countryCode: "+352",
						country: "Luxembourg",
						expectedPhoneNumberLength: [8],
					},
					MG: {
						flag: "🇲🇬",
						countryCode: "+261",
						country: "Madagascar",
						expectedPhoneNumberLength: [9, 10],
					},
					MW: {
						flag: "🇲🇼",
						countryCode: "+265",
						country: "Malawi",
						expectedPhoneNumberLength: [8, 9],
					},
					MY: {
						flag: "🇲🇾",
						countryCode: "+60",
						country: "Malaysia",
						expectedPhoneNumberLength: [9, 10],
					},
					MV: {
						flag: "🇲🇻",
						countryCode: "+960",
						country: "Maldives",
						expectedPhoneNumberLength: [7, 8],
					},
					ML: {
						flag: "🇲🇱",
						countryCode: "+223",
						country: "Mali",
						expectedPhoneNumberLength: [8],
					},
					MT: {
						flag: "🇲🇹",
						countryCode: "+356",
						country: "Malta",
						expectedPhoneNumberLength: [8],
					},
					MH: {
						flag: "🇲🇭",
						countryCode: "+692",
						country: "Marshall Islands",
						expectedPhoneNumberLength: [7],
					},
					MR: {
						flag: "🇲🇷",
						countryCode: "+222",
						country: "Mauritania",
						expectedPhoneNumberLength: [8],
					},
					MU: {
						flag: "🇲🇺",
						countryCode: "+230",
						country: "Mauritius",
						expectedPhoneNumberLength: [7],
					},
					MX: {
						flag: "🇲🇽",
						countryCode: "+52",
						country: "Mexico",
						expectedPhoneNumberLength: [10],
					},
					FM: {
						flag: "🇫🇲",
						countryCode: "+691",
						country: "Micronesia",
						expectedPhoneNumberLength: [7],
					},
					MD: {
						flag: "🇲🇩",
						countryCode: "+373",
						country: "Moldova",
						expectedPhoneNumberLength: [8],
					},
					MC: {
						flag: "🇲🇨",
						countryCode: "+377",
						country: "Monaco",
						expectedPhoneNumberLength: [8],
					},
					MN: {
						flag: "🇲🇳",
						countryCode: "+976",
						country: "Mongolia",
						expectedPhoneNumberLength: [8],
					},
					ME: {
						flag: "🇲🇪",
						countryCode: "+382",
						country: "Montenegro",
						expectedPhoneNumberLength: [8],
					},
					MA: {
						flag: "🇲🇦",
						countryCode: "+212",
						country: "Morocco",
						expectedPhoneNumberLength: [9],
					},
					MZ: {
						flag: "🇲🇿",
						countryCode: "+258",
						country: "Mozambique",
						expectedPhoneNumberLength: [9],
					},
					MM: {
						flag: "🇲🇲",
						countryCode: "+95",
						country: "Myanmar (Burma)",
						expectedPhoneNumberLength: [8, 9],
					},
					NA: {
						flag: "🇳🇦",
						countryCode: "+264",
						country: "Namibia",
						expectedPhoneNumberLength: [8, 9],
					},
					NR: {
						flag: "🇳🇷",
						countryCode: "+674",
						country: "Nauru",
						expectedPhoneNumberLength: [7],
					},
					NP: {
						flag: "🇳🇵",
						countryCode: "+977",
						country: "Nepal",
						expectedPhoneNumberLength: [9, 10],
					},
					NL: {
						flag: "🇳🇱",
						countryCode: "+31",
						country: "Netherlands",
						expectedPhoneNumberLength: [9],
					},
					NZ: {
						flag: "🇳🇿",
						countryCode: "+64",
						country: "New Zealand",
						expectedPhoneNumberLength: [8, 9],
					},
					NI: {
						flag: "🇳🇮",
						countryCode: "+505",
						country: "Nicaragua",
						expectedPhoneNumberLength: [8],
					},
					NE: {
						flag: "🇳🇪",
						countryCode: "+227",
						country: "Niger",
						expectedPhoneNumberLength: [8],
					},
					NG: {
						flag: "🇳🇬",
						countryCode: "+234",
						country: "Nigeria",
						expectedPhoneNumberLength: [10],
					},
					KP: {
						flag: "🇰🇵",
						countryCode: "+850",
						country: "North Korea",
						expectedPhoneNumberLength: [9],
					},
					MK: {
						flag: "🇲🇰",
						countryCode: "+389",
						country: "North Macedonia",
						expectedPhoneNumberLength: [8],
					},
					NO: {
						flag: "🇳🇴",
						countryCode: "+47",
						country: "Norway",
						expectedPhoneNumberLength: [8],
					},
					OM: {
						flag: "🇴🇲",
						countryCode: "+968",
						country: "Oman",
						expectedPhoneNumberLength: [8],
					},
					PK: {
						flag: "🇵🇰",
						countryCode: "+92",
						country: "Pakistan",
						expectedPhoneNumberLength: [10],
					},
					PW: {
						flag: "🇵🇼",
						countryCode: "+680",
						country: "Palau",
						expectedPhoneNumberLength: [7],
					},
					PS: {
						flag: "🇵🇸",
						countryCode: "+970",
						country: "Palestine",
						expectedPhoneNumberLength: [9],
					},
					PA: {
						flag: "🇵🇦",
						countryCode: "+507",
						country: "Panama",
						expectedPhoneNumberLength: [8, 9],
					},
					PG: {
						flag: "🇵🇬",
						countryCode: "+675",
						country: "Papua New Guinea",
						expectedPhoneNumberLength: [9],
					},
					PY: {
						flag: "🇵🇾",
						countryCode: "+595",
						country: "Paraguay",
						expectedPhoneNumberLength: [9],
					},
					PE: {
						flag: "🇵🇪",
						countryCode: "+51",
						country: "Peru",
						expectedPhoneNumberLength: [9],
					},
					PH: {
						flag: "🇵🇭",
						countryCode: "+63",
						country: "Philippines",
						expectedPhoneNumberLength: [10],
					},
					PL: {
						flag: "🇵🇱",
						countryCode: "+48",
						country: "Poland",
						expectedPhoneNumberLength: [9],
					},
					PT: {
						flag: "🇵🇹",
						countryCode: "+351",
						country: "Portugal",
						expectedPhoneNumberLength: [9],
					},
					QA: {
						flag: "🇶🇦",
						countryCode: "+974",
						country: "Qatar",
						expectedPhoneNumberLength: [8],
					},
					RO: {
						flag: "🇷🇴",
						countryCode: "+40",
						country: "Romania",
						expectedPhoneNumberLength: [9],
					},
					RU: {
						flag: "🇷🇺",
						countryCode: "+7",
						country: "Russia",
						expectedPhoneNumberLength: [10, 11],
					},
					RW: {
						flag: "🇷🇼",
						countryCode: "+250",
						country: "Rwanda",
						expectedPhoneNumberLength: [9],
					},
					KN: {
						flag: "🇰🇳",
						countryCode: "+1",
						country: "Saint Kitts and Nevis",
						expectedPhoneNumberLength: [10],
					},
					LC: {
						flag: "🇱🇨",
						countryCode: "+1",
						country: "Saint Lucia",
						expectedPhoneNumberLength: [10],
					},
					VC: {
						flag: "🇻🇨",
						countryCode: "+1",
						country: "Saint Vincent and the Grenadines",
						expectedPhoneNumberLength: [10],
					},
					WS: {
						flag: "🇼🇸",
						countryCode: "+685",
						country: "Samoa",
						expectedPhoneNumberLength: [5],
					},
					SM: {
						flag: "🇸🇲",
						countryCode: "+378",
						country: "San Marino",
						expectedPhoneNumberLength: [10],
					},
					ST: {
						flag: "🇸🇹",
						countryCode: "+239",
						country: "São Tomé and Príncipe",
						expectedPhoneNumberLength: [7],
					},
					SA: {
						flag: "🇸🇦",
						countryCode: "+966",
						country: "Saudi Arabia",
						expectedPhoneNumberLength: [9],
					},
					SN: {
						flag: "🇸🇳",
						countryCode: "+221",
						country: "Senegal",
						expectedPhoneNumberLength: [9],
					},
					RS: {
						flag: "🇷🇸",
						countryCode: "+381",
						country: "Serbia",
						expectedPhoneNumberLength: [9, 10],
					},
					SC: {
						flag: "🇸🇨",
						countryCode: "+248",
						country: "Seychelles",
						expectedPhoneNumberLength: [7],
					},
					SL: {
						flag: "🇸🇱",
						countryCode: "+232",
						country: "Sierra Leone",
						expectedPhoneNumberLength: [8],
					},
					SG: {
						flag: "🇸🇬",
						countryCode: "+65",
						country: "Singapore",
						expectedPhoneNumberLength: [8, 9],
					},
					SK: {
						flag: "🇸🇰",
						countryCode: "+421",
						country: "Slovakia",
						expectedPhoneNumberLength: [9],
					},
					SI: {
						flag: "🇸🇮",
						countryCode: "+386",
						country: "Slovenia",
						expectedPhoneNumberLength: [8],
					},
					SB: {
						flag: "🇸🇧",
						countryCode: "+677",
						country: "Solomon Islands",
						expectedPhoneNumberLength: [7],
					},
					SO: {
						flag: "🇸🇴",
						countryCode: "+252",
						country: "Somalia",
						expectedPhoneNumberLength: [8, 9],
					},
					ZA: {
						flag: "🇿🇦",
						countryCode: "+27",
						country: "South Africa",
						expectedPhoneNumberLength: [9],
					},
					KR: {
						flag: "🇰🇷",
						countryCode: "+82",
						country: "South Korea",
						expectedPhoneNumberLength: [9, 10],
					},
					SS: {
						flag: "🇸🇸",
						countryCode: "+211",
						country: "South Sudan",
						expectedPhoneNumberLength: [9],
					},
					ES: {
						flag: "🇪🇸",
						countryCode: "+34",
						country: "Spain",
						expectedPhoneNumberLength: [9],
					},
					LK: {
						flag: "🇱🇰",
						countryCode: "+94",
						country: "Sri Lanka",
						expectedPhoneNumberLength: [9],
					},
					SD: {
						flag: "🇸🇩",
						countryCode: "+249",
						country: "Sudan",
						expectedPhoneNumberLength: [9],
					},
					SR: {
						flag: "🇸🇷",
						countryCode: "+597",
						country: "Suriname",
						expectedPhoneNumberLength: [7, 8],
					},
					SZ: {
						flag: "🇸🇿",
						countryCode: "+268",
						country: "Eswatini",
						expectedPhoneNumberLength: [8],
					},
					SE: {
						flag: "🇸🇪",
						countryCode: "+46",
						country: "Sweden",
						expectedPhoneNumberLength: [9],
					},
					CH: {
						flag: "🇨🇭",
						countryCode: "+41",
						country: "Switzerland",
						expectedPhoneNumberLength: [9, 10],
					},
					SY: {
						flag: "🇸🇾",
						countryCode: "+963",
						country: "Syria",
						expectedPhoneNumberLength: [9],
					},
					TJ: {
						flag: "🇹🇯",
						countryCode: "+992",
						country: "Tajikistan",
						expectedPhoneNumberLength: [9],
					},
					TZ: {
						flag: "🇹🇿",
						countryCode: "+255",
						country: "Tanzania",
						expectedPhoneNumberLength: [9],
					},
					TH: {
						flag: "🇹🇭",
						countryCode: "+66",
						country: "Thailand",
						expectedPhoneNumberLength: [9],
					},
					TL: {
						flag: "🇹🇱",
						countryCode: "+670",
						country: "Timor-Leste",
						expectedPhoneNumberLength: [7, 8],
					},
					TG: {
						flag: "🇹🇬",
						countryCode: "+228",
						country: "Togo",
						expectedPhoneNumberLength: [8],
					},
					TO: {
						flag: "🇹🇴",
						countryCode: "+676",
						country: "Tonga",
						expectedPhoneNumberLength: [5],
					},
					TT: {
						flag: "🇹🇹",
						countryCode: "+1",
						country: "Trinidad and Tobago",
						expectedPhoneNumberLength: [10],
					},
					TN: {
						flag: "🇹🇳",
						countryCode: "+216",
						country: "Tunisia",
						expectedPhoneNumberLength: [8],
					},
					TR: {
						flag: "🇹🇷",
						countryCode: "+90",
						country: "Turkey",
						expectedPhoneNumberLength: [10],
					},
					TM: {
						flag: "🇹🇲",
						countryCode: "+993",
						country: "Turkmenistan",
						expectedPhoneNumberLength: [8],
					},
					TV: {
						flag: "🇹🇻",
						countryCode: "+688",
						country: "Tuvalu",
						expectedPhoneNumberLength: [5],
					},

					UG: {
						flag: "🇺🇬",
						countryCode: "+256",
						country: "Uganda",
						expectedPhoneNumberLength: [9],
					},
					UA: {
						flag: "🇺🇦",
						countryCode: "+380",
						country: "Ukraine",
						expectedPhoneNumberLength: [9],
					},
					AE: {
						flag: "🇦🇪",
						countryCode: "+971",
						country: "United Arab Emirates",
						expectedPhoneNumberLength: [9],
					},
					GB: {
						flag: "🇬🇧",
						countryCode: "+44",
						country: "United Kingdom",
						expectedPhoneNumberLength: [10, 11],
					},
					US: {
						flag: "🇺🇸",
						countryCode: "+1",
						country: "United States",
						expectedPhoneNumberLength: [10],
					},
					UY: {
						flag: "🇺🇾",
						countryCode: "+598",
						country: "Uruguay",
						expectedPhoneNumberLength: [8],
					},
					UZ: {
						flag: "🇺🇿",
						countryCode: "+998",
						country: "Uzbekistan",
						expectedPhoneNumberLength: [9],
					},
					VU: {
						flag: "🇻🇺",
						countryCode: "+678",
						country: "Vanuatu",
						expectedPhoneNumberLength: [7],
					},
					VA: {
						flag: "🇻🇦",
						countryCode: "+39",
						country: "Vatican City",
						expectedPhoneNumberLength: [10],
					},
					VE: {
						flag: "🇻🇪",
						countryCode: "+58",
						country: "Venezuela",
						expectedPhoneNumberLength: [10],
					},
					VN: {
						flag: "🇻🇳",
						countryCode: "+84",
						country: "Vietnam",
						expectedPhoneNumberLength: [10],
					},
					YE: {
						flag: "🇾🇪",
						countryCode: "+967",
						country: "Yemen",
						expectedPhoneNumberLength: [9],
					},
					ZM: {
						flag: "🇿🇲",
						countryCode: "+260",
						country: "Zambia",
						expectedPhoneNumberLength: [9],
					},
					ZW: {
						flag: "🇿🇼",
						countryCode: "+263",
						country: "Zimbabwe",
						expectedPhoneNumberLength: [9],
					},
				},
			};
		},
		computed: {
			phoneNumberPattern() {
				let countryPhoneInformation =
					this.phoneInformationByCountry[this.selectedCountry];

				let expectedLengthArray =
					countryPhoneInformation.expectedPhoneNumberLength;

				if (expectedLengthArray.length === 1) {
					return `^$|^[0-9]{${expectedLengthArray[0]}}$`;
				} else if (expectedLengthArray.length === 2) {
					return `^$|^[0-9]{${expectedLengthArray[0]},${expectedLengthArray[1]}}$`;
				}
				return "^$|^[0-9]*$";
			},
			formattedPhoneNumber() {
				return (
					this.phoneInformationByCountry[this.selectedCountry]
						.countryCode + this.phoneNumber
				);
			},
			isPhoneNumberValid() {
				return new RegExp(this.phoneNumberPattern).test(this.phoneNumber);
			},
		},
		methods: {
			checkPhoneNumberLength() {
				let message = "";
				let warningTextArea = this.$refs.warningTextArea;
				let countryPhoneInformation =
					this.phoneInformationByCountry[this.selectedCountry];

				let expectedLengthArray =
					countryPhoneInformation.expectedPhoneNumberLength;

				if (this.phoneNumber.length === 0) {
					message = "";
				} else if (this.isPhoneNumberValid) {
					message = "The phone number seems to be valid";
				} else {
					if (expectedLengthArray.length === 2) {
						message = `Possible invalid phone number: Expecting ${expectedLengthArray[0]} to ${expectedLengthArray[1]} digits, you entered ${this.phoneNumber.length}. If you believe the phone number is correct, you can disregard this message.`;
					} else if (expectedLengthArray.length === 1) {
						message = `Possible invalid phone number: Expecting ${expectedLengthArray[0]} digits, you entered ${this.phoneNumber.length}. If you believe the phone number is correct, you can disregard this message.`;
					} else {
						message = `Phone Number may be invalid. If you believe the phone number is correct, you can disregard this message.`;
					}
				}

				warningTextArea.innerHTML = message;
			},
		},
		watch: {
			formattedPhoneNumber: {
				handler(newValue, oldValue) {
					console.log(
						"Formatted Phone Number changed from " +
							oldValue +
							" to " +
							newValue
					);
					this.$emit("update:modelValue", newValue);
				},
			},
		},
	};
</script>
